import { diff } from "radash";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";

import type { UseInputFieldAtomEmits } from "./useInputFieldAtom";
import type { UseInputIconAtomEmits } from "./useInputIconAtom";

import {
  useInputFieldAtomEmits,
  useInputFieldAtomProps,
  useInputFieldAtomScoped,
} from "./useInputFieldAtom";
import {
  useInputIconAtomEmits,
  useInputIconAtomProps,
  useInputIconAtomScoped,
} from "./useInputIconAtom";
import {
  useInputWrapperAtomProps,
  useInputWrapperAtomScoped,
} from "./useInputWrapperAtom";

const scoped = propsDefinition({
  ...useInputFieldAtomScoped,
  ...useInputWrapperAtomScoped,
  ...useInputIconAtomScoped,
});

const props = propsDefinition({
  ...useInputIconAtomProps,
  ...useInputFieldAtomProps,
  ...useInputWrapperAtomProps,
});

const emits = emitsDefinition([
  ...useInputIconAtomEmits,
  ...useInputFieldAtomEmits,
  "click",
  "mousedown",
  "mouseup",
]);

type UseInputAtomProps = DefineProps<typeof props>;
type UseInputAtomEmits = UseInputFieldAtomEmits &
  UseInputIconAtomEmits &
  ((event: "click" | "mousedown" | "mouseup") => void);

function use(props: UseInputAtomProps, emit: UseInputAtomEmits) {
  const inputWrapperAtom = {
    props: pickProps(props, useInputWrapperAtomProps),
    on: reEmit(emit, ["click", "mousedown", "mouseup"]),
  };

  const inputFieldAtom = {
    props: pickProps(props, useInputFieldAtomProps),
    on: reEmit(emit, diff(useInputFieldAtomEmits, ["click"])),
  };

  const inputIconAtom = {
    props: pickProps(props, useInputIconAtomProps),
    on: reEmit(emit, useInputIconAtomEmits),
  };

  return { inputWrapperAtom, inputFieldAtom, inputIconAtom };
}

export default { use, props, emits };
export type { UseInputAtomEmits };
export {
  emits as useInputAtomEmits,
  props as useInputAtomProps,
  scoped as useInputAtomScoped,
};
