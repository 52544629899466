<template>
  <transition-group
    v-if="shouldShow"
    class="s-chips-display"
    :class="[`s-chips-display--${variant}`]"
    name="s-chips-display"
    tag="div"
  >
    <component
      :is="tag"
      v-for="{ value, label } in items"
      :key="value"
      class="s-chips-display__item"
      :class="{ 's-chips-display__item--removable': removable }"
      :type="tag === 'button' ? 'button' : null"
      @click.stop="removeValue(value)"
    >
      {{ label }}
      <font-awesome-icon
        v-if="removable"
        class="s-chips-display__item-icon"
        :icon="faXmark"
      />
    </component>
  </transition-group>
</template>

<script setup lang="ts">
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useMultiValueDisplay from "@/lib/components/logic/atoms/useMultiValueDisplay";
const props = defineProps(useMultiValueDisplay.props);
const emit = defineEmits(useMultiValueDisplay.emits);

const { shouldShow, items, removeValue, removable, tag, variant } =
  useMultiValueDisplay.use(props, emit);
</script>

<style lang="postcss">
.s-chips-display {
  @apply relative flex flex-wrap gap-x-3 gap-y-2;

  &__item {
    @apply flex h-6 items-center rounded leading-none;

    &--removable:hover {
      @apply cursor-pointer;

      .s-chips-display__item-icon {
        @apply bg-danger-100 text-danger-700;
      }
    }

    &-icon {
      @apply -mr-0.5 ml-1 h-3 w-3 rounded-full p-0.5 transition-colors;
    }
  }

  &--opaque &__item {
    @apply bg-primary-100 px-2 text-sm text-primary-800 transition-bg;

    &--removable:hover {
      @apply bg-danger-200;
    }

    &-icon {
      @apply bg-transparent text-primary-800;
    }
  }

  &--clear &__item {
    @apply text-gray-500 transition-text;

    &--removable:hover {
      @apply cursor-pointer text-danger-700;
    }

    &-icon {
      @apply text-gray-500;
    }
  }

  &-enter {
    &-active {
      @apply opacity-0 transition-opacity;
    }

    &-to {
      @apply opacity-100;
    }
  }

  &-move {
    @apply transition-transform;
  }

  &-leave-active {
    transition-duration: 0ms;
    @apply invisible absolute;
  }
}
</style>
