<template>
  <div class="s-review-score-atom">
    <span
      v-if="showNumberScore"
      class="s-review-score-atom__rating"
      :class="[`s-review-score-atom__rating--${size}`]"
    >
      <span class="s-review-score-atom__rating--strong">
        {{ scoreDisplay }}
      </span>
      /{{ length }}
    </span>
    <component
      :is="element"
      v-for="(star, index) in stars"
      :key="index"
      class="s-review-score-atom__star"
      :class="`s-review-score-atom--${size}`"
      type="button"
      v-on="star.on"
    >
      <font-awesome-icon
        v-if="star.fillState === 'partial' || star.fillState === 'filled'"
        class="s-review-score-atom__star--filled"
        :icon="icon"
      />
      <font-awesome-icon
        v-if="star.fillState === 'partial' || star.fillState === 'empty'"
        :class="[`s-review-score-atom__star--${star.fillState}`]"
        :icon="icon"
      />
    </component>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useReviewScoreAtom from "@/lib/components/logic/atoms/useReviewScoreAtom";

const emits = defineEmits(useReviewScoreAtom.emits);
const props = defineProps(useReviewScoreAtom.props);

const {
  starFraction,
  stars,
  icon,
  size,
  scoreDisplay,
  showNumberScore,
  element,
  length,
} = useReviewScoreAtom.use(props, emits);
</script>

<style lang="postcss">
.s-review-score-atom {
  @apply flex items-center;
  &__star {
    @apply mx-px mb-0.5 flex;

    &--filled {
      @apply h-full w-full text-warning-300;
    }

    &--partial {
      @apply h-full w-full text-gray-200;
      clip-path: polygon(
        100% 100%,
        v-bind(starFraction) 100%,
        v-bind(starFraction) 0%,
        100% 0%
      );
    }

    &--empty {
      @apply h-full w-full text-gray-200;
    }
  }
  &__rating {
    @apply inline-block leading-none text-gray-800;

    &--strong {
      @apply font-medium text-gray-900;
    }

    &--sm {
      @apply mr-0.5 text-xs;
    }
    &--md {
      @apply mr-1 text-base;
    }
    &--lg {
      @apply mr-1.5 text-lg;
    }
  }
  &--sm {
    @apply h-3 w-3;

    .s-review-score-atom__star--partial {
      @apply -ml-3;
    }
  }
  &--md {
    @apply h-4 w-4;
    .s-review-score-atom__star--partial {
      @apply -ml-4;
    }
  }
  &--lg {
    @apply h-5 w-5;

    .s-review-score-atom__star--partial {
      @apply -ml-5;
    }
  }
}
</style>
