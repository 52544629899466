import type { Ref } from "vue";

import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type {
  EventsCallback,
  ValidationRule,
  ValidationRuleBase,
} from "@/lib/validation/validation.types";

import { useAutoI18n, useI18n } from "@/lib/composables/useI18n";
import { reactivePick } from "@/lib/helpers/reactivity";
import { required } from "@/lib/validation/rules";

type UseRulesProps<Value = unknown> = Readonly<{
  errorLabel?: string;
  label?: string;
  name: string;
  required: boolean;
  rules: ValidationRuleBase<NoInfer<Value>>[];
  validationEvents: EventsCallback<NoInfer<Value>> | string[];
  value: NoInfer<Value>;
}>;

function useRules<Value>(
  props: UseRulesProps<Value>,
  error: Ref<string | null>,
) {
  const inferredRules = computed(() => {
    const rules = [];
    if (props.required) {
      rules.push(required<Value>());
    }

    return rules;
  });

  const allRules = computed(() => {
    const rules = [...inferredRules.value, ...props.rules];
    return mergeRules(rules)
      .map((rule) => fillInRuleDefaults(rule))
      .sort(
        (first, second) => Number(second.blocking) - Number(first.blocking),
      );
  });

  const blockingRules = computed(() => {
    return allRules.value.filter((rule) => rule.blocking);
  });

  function mergeRules(rules: ValidationRuleBase<Value>[]) {
    return rules.reduce((merged: ValidationRuleBase<Value>[], rule) => {
      const existingRule = merged.find(({ name }) => name === rule.name);

      if (existingRule) {
        const existingRuleIndex = merged.findIndex(
          ({ name }) => name === rule.name,
        );
        merged[existingRuleIndex] = { ...existingRule, ...rule };
      } else {
        merged.push(rule);
      }

      return merged;
    }, []);
  }

  const { errorLabel } = useAutoI18n(
    toRef(props, "name"),
    reactivePick(props, ["errorLabel", "label"]),
  );

  const { tr, trOptional } = useI18n();

  function fillInRuleDefaults(
    rule: ValidationRuleBase<Value>,
  ): ValidationRule<Value> {
    const events = () => {
      const ruleEvents = rule.events || props.validationEvents;

      return Array.isArray(ruleEvents)
        ? ruleEvents
        : ruleEvents(error.value, props.value);
    };

    return {
      blocking: true,
      color: "danger",
      ...rule,
      events: events(),
      message: rule.message(errorLabel.value, props.value, props.name, {
        tr,
        trOptional,
      }),
    };
  }

  return {
    allRules,
    blockingRules,
  };
}

export type { UseRulesProps };
export { useRules };
