<template>
  <ul
    class="s-listbox"
    :class="{ [`s-listbox--size-${size}`]: true }"
    v-bind="container.props"
  >
    <s-option
      v-for="item in items"
      :key="item.key"
      v-bind="item.props"
      v-on="item.on"
    />
  </ul>
</template>

<script setup lang="ts">
import SOption from "@/lib/components/atoms/option/SOption.vue";
import useListbox from "@/lib/components/logic/atoms/useListbox";

const props = defineProps(useListbox.props);
const emit = defineEmits(useListbox.emits);

const { container, items, size } = useListbox.use(props, emit);
</script>

<style lang="postcss">
.s-listbox {
  @apply absolute left-0 top-0 z-20 m-0 flex max-h-96 list-none flex-col overflow-auto rounded bg-white p-0 shadow;
  scroll-behavior: smooth;

  &--size {
    &-lg {
      @apply max-h-112;
    }

    &-md {
      @apply max-h-96;
    }

    &-sm {
      @apply max-h-80;
    }
  }
}
</style>
