<template>
  <div class="s-file-input">
    <s-label-atom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />
    <s-description
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />
    <s-upload v-bind="uploadAtom.props" v-on="uploadAtom.on" />
    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" class="s-file-input__error" />
    </slot>
    <s-preview-atom v-bind="previewAtom.props" v-on="previewAtom.on" />
  </div>
</template>

<script setup lang="ts">
import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import SPreviewAtom from "@/lib/components/atoms/upload/SPreviewAtom.vue";
import SUpload from "@/lib/components/atoms/upload/SUpload.vue";
import useFileInput from "@/lib/components/logic/molecules/useFileInput";

const props = defineProps(useFileInput.props);
const emit = defineEmits(useFileInput.emits);

const {
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  uploadAtom,
  previewAtom,
  subtextAtom,
  errorProps,
  errorComponent,
  error,
} = useFileInput.use(props, emit);
</script>

<style lang="postcss">
.s-file-input {
  @apply flex flex-col;

  &__error {
    @apply mb-3;
  }
}
</style>
