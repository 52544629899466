<template>
  <s-autocomplete v-bind="autocomplete.props" v-on="autocomplete.on">
    <template v-for="(_index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </s-autocomplete>
</template>

<script setup lang="ts">
import useComboboxPreset from "@/lib/components/logic/molecules/useComboboxPreset";
import SAutocomplete from "@/lib/components/molecules/autocomplete/SAutocomplete.vue";

const props = defineProps(useComboboxPreset.props);
const emit = defineEmits(useComboboxPreset.emits);

const { autocomplete } = useComboboxPreset.use(props, emit);
</script>
