import { toRef } from "@vueuse/core";

import type { UseCheckboxRadioInputEmits } from "@/lib/components/logic/atoms/useCheckboxRadioInput";
import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import {
  useCheckboxRadioInputEmits,
  useCheckboxRadioInputProps,
} from "@/lib/components/logic/atoms/useCheckboxRadioInput";
import { useTooltipScoped } from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy } from "@/lib/composables";
import {
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { mergeReactive, reactivePick } from "@/lib/helpers/reactivity";

const props = propsDefinition({
  ...useCheckboxRadioInputProps,
  ...useTooltipScoped,
  size,
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  checked: { type: Boolean, default: false },
  label: { type: String, required: false },
  imageUrl: { type: String, required: false },
  imageContain: { type: Boolean, default: false },
});

const emits = useCheckboxRadioInputEmits;

type UseCheckboxRadioProps = DefineProps<typeof props>;
type UseCheckboxRadioEmits = UseCheckboxRadioInputEmits;

function use(props: UseCheckboxRadioProps, emit: UseCheckboxRadioEmits) {
  const { describedBy, ids } = useDescribedBy(
    reactivePick(props, ["tooltip"]),
    toRef(() => props.describedBy),
  );

  const inputAtom = {
    props: mergeReactive(pickProps(props, useCheckboxRadioInputProps), {
      describedBy,
    }),
    on: reEmit(emit, useCheckboxRadioInputEmits),
  };

  const tooltipAtom = {
    props: mergeReactive(props, {
      tooltipId: toRef(() => ids.tooltip),
      tooltip: props.tooltip,
    }),
  };

  return {
    inputAtom,
    tooltipAtom,
    label: toRef(() => props.label),
    disabled: toRef(() => props.disabled),
    checked: toRef(() => props.checked),
    indeterminate: toRef(() => props.indeterminate),
    imageUrl: toRef(() => props.imageUrl),
    imageContain: toRef(() => props.imageContain),
    size: toRef(() => props.size),
  };
}

export { emits as useCheckboxRadioEmits, props as useCheckboxRadioProps };
export type { UseCheckboxRadioEmits };
export default { use, props, emits };
