import {
  faSpinnerThird,
  type IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { computed, type PropType } from "vue";

import type { UseButtonEmits } from "@/lib/components/logic/atoms/useButton";
import type { DefineProps } from "@/lib/composables/componentComposable";

import useButton from "@/lib/components/logic/atoms/useButton";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const props = propsDefinition({
  icon: { type: Object as PropType<IconDefinition>, required: false },
  ...useButton.props,
});

const emits = emitsDefinition(useButton.emits);

type UseButtonIconProps = DefineProps<typeof props>;

function use(props: UseButtonIconProps, emit: UseButtonEmits) {
  const internalIcon = computed(() => {
    return props.loading ? faSpinnerThird : props.icon;
  });

  const {
    tag,
    buttonProps,
    actionType,
    click,
    size,
    color,
    loading,
    disabled,
  } = useButton.use(props, emit);

  return {
    icon: internalIcon,
    tag,
    buttonProps,
    actionType,
    click,
    size,
    color,
    loading,
    disabled,
  };
}

export type { UseButtonIconProps };
export { emits as useButtonIconEmits, props as useButtonIconProps };
export default { props, use, emits };
