<template>
  <s-transition name="fade">
    <div v-if="activeImage" class="s-lightbox" v-on="input.on">
      <img
        :alt="activeImage.name"
        class="s-lightbox__image"
        :src="activeImage.url"
      />
    </div>
  </s-transition>
</template>

<script setup lang="ts">
import STransition from "@/lib/components/behaviour/STransition.vue";
import useLightbox from "@/lib/components/logic/atoms/useLightbox";

const props = defineProps(useLightbox.props);
const emit = defineEmits(useLightbox.emits);

const { input, activeImage } = useLightbox.use(props, emit);
</script>

<style lang="postcss">
.s-lightbox {
  @apply fixed inset-0 z-50 grid grid-cols-1 grid-rows-1 place-items-center p-6;
  background-color: hsla(0, 0%, 0%, 0.5);

  &__image {
    @apply max-h-full rounded;
  }
}
</style>
