<template>
  <div class="s-alert" :class="[`s-alert--${color}`, `s-alert--size-${size}`]">
    <font-awesome-icon
      v-if="prefixIcon"
      class="s-alert__icon s-alert__prefix"
      :icon="prefixIcon"
    />
    <div class="s-alert__content">
      <slot />
    </div>
    <font-awesome-icon
      v-if="suffixIcon"
      class="s-alert__icon s-alert__suffix"
      :icon="suffixIcon"
    />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useAlert from "@/lib/components/logic/atoms/useAlert";

const props = defineProps(useAlert.props);
const { color, prefixIcon, suffixIcon, size } = useAlert.use(props);
</script>

<style lang="postcss">
.s-alert {
  @apply flex items-start rounded border;

  &--size {
    &-lg {
      @apply min-h-14 px-6 py-4 text-base;

      .s-alert {
        &__prefix {
          @apply mr-6;
        }

        &__suffix {
          @apply ml-6;
        }
      }
    }

    &-md {
      @apply min-h-12 px-5 py-3 text-sm;

      .s-alert {
        &__prefix {
          @apply mr-5;
        }

        &__suffix {
          @apply ml-5;
        }
      }
    }

    &-sm {
      @apply min-h-10 px-4 py-2 text-sm;

      .s-alert {
        &__prefix {
          @apply mr-4;
        }

        &__suffix {
          @apply ml-4;
        }
      }
    }
  }

  &__icon {
    @apply block h-1.5em w-1em;
  }

  &__content {
    @apply flex-grow;
  }

  &--primary {
    @apply border-primary-200 bg-primary-100 text-primary-700;
  }

  &--accent {
    @apply border-accent-200 bg-accent-100 text-accent-700;
  }

  &--success {
    @apply border-success-200 bg-success-100 text-success-700;
  }

  &--warning {
    @apply border-warning-300 bg-warning-100 text-warning-950;
  }

  &--danger {
    @apply border-danger-200 bg-danger-100 text-danger-700;
  }

  &--gray {
    @apply border-gray-200 bg-gray-100 text-gray-700;
  }
}
</style>
