<template>
  <div
    :ref="container.ref"
    class="s-select"
    :class="{
      's-select--is-open': isOpen,
    }"
    v-on="container.on"
  >
    <s-label-atom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />

    <s-description
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />

    <s-input-wrapper-atom
      :ref="comboboxAtom.ref"
      v-bind="comboboxAtom.props"
      v-on="comboboxAtom.on"
    >
      <s-input-icon-atom
        v-bind="inputIconAtom.props"
        location="prefix"
        v-on="inputIconAtom.on"
      />
      <s-input-text-atom v-bind="inputTextAtom.props">
        <template v-if="!multiValueDisplayAtom.if.value">
          {{ comboboxText }}
        </template>
        <s-chips-display
          v-else
          removable
          variant="opaque"
          v-bind="multiValueDisplayAtom.props"
          v-on="multiValueDisplayAtom.on"
        />
      </s-input-text-atom>
      <s-input-icon-atom
        v-bind="inputIconAtom.props"
        location="suffix"
        v-on="inputIconAtom.on"
      />
    </s-input-wrapper-atom>

    <s-transition name="fade">
      <keep-alive>
        <slot v-if="isOpen" name="listbox" v-bind="listboxAtom">
          <s-listbox
            v-if="isOpen"
            :ref="listboxAtom.ref"
            v-bind="listboxAtom.props"
            v-on="listboxAtom.on"
          />
        </slot>
      </keep-alive>
    </s-transition>

    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />

    <slot v-if="error" :name="errorComponent" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SInputIconAtom from "@/lib/components/atoms/input/SInputIconAtom.vue";
import SInputTextAtom from "@/lib/components/atoms/input/text/SInputTextAtom.vue";
import SInputWrapperAtom from "@/lib/components/atoms/input/wrapper/SInputWrapperAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SListbox from "@/lib/components/atoms/listbox/SListbox.vue";
import SChipsDisplay from "@/lib/components/atoms/multi-value-display/SChipsDisplay.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import STransition from "@/lib/components/behaviour/STransition.vue";
import useSelect from "@/lib/components/logic/molecules/useSelect";

const props = defineProps(useSelect.props);
const emit = defineEmits(useSelect.emits);

const {
  container,
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  inputTextAtom,
  inputIconAtom,
  subtextAtom,
  comboboxAtom,
  listboxAtom,
  isOpen,
  multiValueDisplayAtom,
  comboboxText,
  error,
  errorComponent,
  errorProps,
} = useSelect.use(props, emit);
</script>

<style lang="postcss">
.s-select {
  @apply max-w-full;

  &--is-open .s-input-icon-atom--suffix .fa-chevron-down {
    transform: rotate(180deg);
  }

  .s-input-wrapper-atom--enabled {
    @apply cursor-pointer;
  }
}
</style>
