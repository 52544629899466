import type { Event as SentryEvent } from "@sentry/types";

import type { SentryProject } from "@/lib/integrations/sentry/sentry";

function getEventProject(event: SentryEvent) {
  if (!window.sentryProjects) {
    return;
  }

  // Stolen from Sentry's allowedUrl source code
  const frames = event.exception?.values?.[0]?.stacktrace?.frames;
  const filename =
    frames &&
    [...frames].reverse().find(({ filename }) => {
      return filename !== "<anonymous>" && filename !== "[native code]";
    })?.filename;

  if (!filename) {
    return;
  }

  return Object.values(window.sentryProjects).find(
    ({ path }) => path && filename.includes(path),
  );
}

function getProjectRelease({
  name,
  version,
}: Pick<SentryProject, "name" | "version">) {
  return version && `${name}@${version}`;
}

function addSentryProject(project: SentryProject) {
  window.sentryProjects = window.sentryProjects ?? {};
  window.sentryProjects[project.name] = project;
}

function setEventProject(event: SentryEvent, project?: SentryProject) {
  if (!project) {
    return event;
  }
  const version = getProjectRelease(project);
  if (version) {
    event.release = version;
  }
  event.tags = { ...event.tags, project: project.name };
  return event;
}

export {
  addSentryProject,
  getEventProject,
  getProjectRelease,
  setEventProject,
};
