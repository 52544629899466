import type { DeepReadonly, PropType } from "vue";

import { toRef } from "@vueuse/core";
import { computed, reactive } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type {
  OptionItem,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

import { size } from "@/lib/components/logic/atoms/input/props";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  size,
  multiple: { type: Boolean, default: false },
});

const props = propsDefinition({
  ...scoped,
  items: {
    type: Array as PropType<DeepReadonly<OptionItem[]>>,
    default: () => [],
  },
  listboxId: { type: String, required: false },
});

type UseListboxProps = DefineProps<typeof props>;
interface UseListboxEmits {
  (event: "click", value: OptionValue): void;
  (event: "input", checked: boolean, value: OptionValue): void;
}

const emits = emitsDefinition(["click", "input"]);

function use(props: UseListboxProps, emit: UseListboxEmits) {
  function onOptionClick(checked: boolean, value: OptionValue) {
    emit("click", value);
    emit("input", checked, value);
  }

  const items = computed(() => {
    return props.items.map((item) => ({
      key: item.id,
      props: reactive({
        size: props.size,
        ...item,
      }),
      on: {
        click: (checked: boolean) => onOptionClick(checked, item.value),
      },
    }));
  });

  return {
    container: {
      props: reactive({
        role: "listbox",
        id: toRef(props, "listboxId"),
      }),
    },
    size: toRef(props, "size"),
    items,
  };
}

export { emits as useListboxEmits, props as useListboxProps };
export type { UseListboxEmits };
export default { use, props, emits };
