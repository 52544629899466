import type { EventHint } from "@sentry/browser";
import type { Contexts, Event as SentryEvent } from "@sentry/types";

import * as Sentry from "@sentry/browser";
import { isbot } from "isbot";

import { isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser";
import { init } from "@/lib/integrations/sentry/root";
import { trackImageLoading } from "@/lib/integrations/sentry/trackers";

/*
  You can only have one Sentry instance running on a page.
  We use micro frontends that each have their own Sentry project.
  So we need some logic to send the errors to the right project by defining them globally
 */

interface SentryProject {
  addData?: (
    event: Readonly<SentryEvent>,
    hint: Readonly<EventHint>,
  ) => {
    contexts?: Contexts;
    tags?: Record<string, boolean | number | string | null | undefined>;
  };
  dsn: string;
  environment: string;
  name: string;
  path: string | null;
  version?: string;
}

type SentryRootProject = Omit<SentryProject, "path">;

declare global {
  interface Window {
    sentry?: {
      captureException: typeof Sentry.captureException;
      captureMessage: typeof Sentry.captureMessage;
    };
    sentryProjects?: Record<string, SentryProject>;
  }
}

function initSentryRoot(rootProject: SentryRootProject) {
  if (!isSupportedBrowser() || isbot(navigator.userAgent) || !rootProject.dsn) {
    return;
  }

  init(rootProject);

  window.sentry = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
  };

  trackImageLoading();
}

function withConsoleError<Params extends unknown[], Return>(
  callback?: (...params: Params) => Return,
): (...params: Params) => Return | undefined {
  return (...params) => {
    console.error(params[0]);
    return callback?.(...params);
  };
}

/*
  Gets the global Sentry instance, this avoids having to include Sentry in every micro frontend
 */
function getSentry() {
  return {
    captureException: withConsoleError(window.sentry?.captureException),
    captureExceptionSilent: window.sentry?.captureException,
    captureMessage: withConsoleError(window.sentry?.captureMessage),
    captureMessageSilent: window.sentry?.captureMessage,
  };
}

export { getSentry, initSentryRoot };
export { addSentryProject } from "./project";
export { trackVueErrors } from "./trackers";
export type { SentryProject, SentryRootProject };
