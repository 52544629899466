import type { PropType } from "vue";

import { toReactive, toRef } from "@vueuse/core";
import { nanoid } from "nanoid";
import { computed, nextTick, reactive, ref, watch } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile, SFileLocal } from "@/lib/composables/useFileUpload";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import {
  useDescriptionIf,
  useDescriptionScoped,
  useLabelScoped,
  useTooltipScoped,
} from "@/lib/components/logic";
import { usePreviewScoped } from "@/lib/components/logic/atoms/usePreview";
import {
  useSubtextIf,
  useSubtextScoped,
} from "@/lib/components/logic/atoms/useSubtext";
import { useUploadScoped } from "@/lib/components/logic/atoms/useUpload";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useDescribedBy } from "@/lib/composables/useDescribedBy";
import { useAutoI18n } from "@/lib/composables/useI18n";
import { useModel } from "@/lib/composables/useModel";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import { acceptRule, maxSizeRule } from "@/lib/validation/rules/file";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useValidationProviderScoped(),
  ...useLabelScoped,
  ...useDescriptionScoped,
  ...useTooltipScoped,
  ...useUploadScoped,
  ...useSubtextScoped,
  showRequiredType: {
    type: String as PropType<"none" | "optional" | "required">,
    default: "optional",
  },
  name: { type: String, required: true, default: () => nanoid(10) },
  loading: { type: Boolean, default: false },
  value: { type: Array as PropType<(SFile | SFileLocal)[]>, default: () => [] },
  accept: { type: String, required: false },
  maxSize: { type: [String, Number], required: false },
});

const emits = emitsDefinition([
  "input",
  "update:loading",
  ...useValidationProviderEmits,
]);

type UseUploadProps = DefineProps<typeof props>;
type UseUploadEmits = UseValidationProviderEmits & {
  (event: "input", value: UseUploadProps["value"]): void;
  (event: "update:loading", value: UseUploadProps["loading"]): void;
};

function use(props: UseUploadProps, emit: UseUploadEmits) {
  const modelValue = useModel("value", props, emit, { local: true });
  const validationModelValue = ref(modelValue.value);
  watch(modelValue, () => (validationModelValue.value = modelValue.value));

  const loading = useModel("loading", props, emit, { local: true });

  const { label, tooltip, description, subtext, errorLabel } = useAutoI18n(
    toRef(() => props.name),
    reactivePick(props, [
      "label",
      "tooltip",
      "description",
      "subtext",
      "errorLabel",
    ]),
  );

  // Ids
  const id = nanoid(10);
  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  // Validation
  const inferredRules = computed(() => {
    const rules = [...props.rules];

    if (props.accept) {
      rules.unshift(acceptRule(props.accept));
    }

    if (props.maxSize) {
      rules.unshift(maxSizeRule(Number(props.maxSize)));
    }

    return rules;
  });

  const {
    validationListeners,
    error,
    errorComponent,
    errorProps,
    validateEvent,
    invalid,
  } = useValidationProvider(
    mergeReactive(props, {
      value: validationModelValue,
      rules: inferredRules,
      loading,
      errorLabel,
    }),
    emit,
  );

  async function rejectInvalid(files: (SFile | SFileLocal)[]) {
    validationModelValue.value = files;
    await validateEvent("fileUpload");

    if (invalid.value) {
      await nextTick(); // Remove files AFTER triggering validation, to show the message but still reject files
      const rules = inferredRules.value.filter(({ name }) =>
        ["maxSize", "accept"].includes(name),
      );
      validationModelValue.value = files.filter((file) => {
        return rules.every((rule) => rule.validate(file));
      });
    }

    return validationModelValue.value;
  }

  async function onUploadInput(files: (SFile | SFileLocal)[]) {
    modelValue.value = await rejectInvalid(files);
  }

  function removeFile(removedUuid: string) {
    modelValue.value = modelValue.value.filter(
      (file) => file.uuid !== removedUuid,
    );
  }

  /*
    Template
   */

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabelScoped), { for: id, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltipScoped), {
      tooltipId: toRef(() => ids.tooltip),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescriptionIf(props),
    props: mergeReactive(pickProps(props, useDescriptionScoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const uploadAtom = {
    props: mergeReactive(pickProps(props, useUploadScoped), {
      id,
      invalid,
      value: validationModelValue,
      describedBy,
    }),
    on: mergeListeners(
      { input: onUploadInput },
      toReactive(validationListeners),
    ),
  };

  const previewAtom = {
    props: mergeReactive(pickProps(props, usePreviewScoped), {
      files: modelValue,
    }),
    on: { removedFile: removeFile },
  };

  const subtextAtom = {
    if: useSubtextIf(props),
    props: mergeReactive(pickProps(props, useSubtextScoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    uploadAtom,
    previewAtom,
    subtextAtom,
    errorProps,
    errorComponent,
    error,
  };
}

export default { props, use, emits };
export { emits as useFileInputEmits, props as useFileInputProps };
