/*
 Algorithm: https://en.wikipedia.org/wiki/Jaro%E2%80%93Winkler_distance
 Calculates the similarity between strings
 */

interface CompareOptions {
  caseSensitive?: boolean;
}

function jaro(string1: string, string2: string, options: CompareOptions = {}) {
  let matches = 0;
  const settings = { caseSensitive: true, ...options };

  if (string1.length === 0 || string2.length === 0) {
    return 0;
  }

  if (!settings.caseSensitive) {
    string1 = string1.toLowerCase();
    string2 = string2.toLowerCase();
  }

  if (string1 === string2) {
    return 1;
  }

  const range = Math.floor(Math.max(string1.length, string2.length) / 2) - 1;
  const string1Matches = new Array(string1.length);
  const string2Matches = new Array(string2.length);

  for (let index = 0; index < string1.length; index++) {
    const low = index >= range ? index - range : 0;
    const high =
      index + range <= string2.length - 1 ? index + range : string2.length - 1;

    for (let index2 = low; index2 <= high; index2++) {
      if (
        string1Matches[index] !== true &&
        string2Matches[index2] !== true &&
        string1[index] === string2[index2]
      ) {
        ++matches;
        string1Matches[index] = true;
        string2Matches[index2] = true;
        break;
      }
    }
  }

  if (matches === 0) {
    return 0;
  }

  // Count the transpositions
  let count = 0;
  let transpositionCount = 0;

  for (let index = 0; index < string1.length; index++) {
    if (string1Matches[index] === true) {
      let index2;

      for (index2 = count; index2 < string2.length; index2++) {
        if (string2Matches[index2] === true) {
          count = index2 + 1;
          break;
        }
      }

      if (string1[index] !== string2[index2]) {
        ++transpositionCount;
      }
    }
  }

  return (
    (matches / string1.length +
      matches / string2.length +
      (matches - transpositionCount / 2) / matches) /
    3
  );
}

function compare(
  string1: string,
  string2: string,
  options: CompareOptions = {},
) {
  const jaroResult = jaro(string1, string2, options);

  if (jaroResult <= 0.7) {
    return jaroResult;
  }

  // Winkler similarity, prefers strings that have a similar start
  let commonPrefixLength = 0;
  while (
    string1[commonPrefixLength] === string2[commonPrefixLength] &&
    commonPrefixLength < 4
  ) {
    ++commonPrefixLength;
  }

  return jaroResult + commonPrefixLength * 0.1 * (1 - jaroResult);
}

function compareMany(
  string: string,
  comparisons: readonly string[],
  options: CompareOptions = {},
) {
  return comparisons.map((value: string) => {
    return { rating: compare(string, value, options), value };
  });
}

function bestMatch(
  string: string,
  comparisons: readonly string[],
  { threshold = 0, ...options }: CompareOptions & { threshold?: number } = {},
) {
  const match = compareMany(string, comparisons, options).reduce<{
    rating: number;
    value: string;
  } | null>((currentBestMatch, currentMatch) => {
    if (
      currentMatch.rating >= threshold &&
      (!currentBestMatch || currentMatch.rating > currentBestMatch.rating)
    ) {
      return currentMatch;
    }
    return currentBestMatch;
  }, null);

  return match?.value ?? null;
}

export { bestMatch, compare, compareMany };
