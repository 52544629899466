import type { MaybeRefOrGetter } from "@vueuse/core";

import { refAutoReset, toRef, toValue } from "@vueuse/core";

export function useIsOpen(
  overrides: {
    isOpen?: MaybeRefOrGetter<boolean>;
    onClose?: () => Promise<void> | void;
    onOpen?: () => Promise<void> | void;
  } = {},
  {
    closable = true,
    openable = true,
  }: {
    closable?: MaybeRefOrGetter<boolean>;
    openable?: MaybeRefOrGetter<boolean>;
  } = {},
) {
  const isOpen = toRef(overrides.isOpen ?? false);
  const triedToClose = refAutoReset(false, 100);
  const triedToOpen = refAutoReset(false, 100);

  function close() {
    const canClose = toValue(closable) && isOpen.value;
    if (canClose) {
      isOpen.value = false;
      void overrides.onClose?.();
    } else {
      triedToClose.value = true;
    }
    return canClose;
  }

  function open() {
    const canOpen = toValue(openable) && !isOpen.value;
    if (canOpen) {
      isOpen.value = true;
      void overrides.onOpen?.();
    } else {
      triedToOpen.value = true;
    }
    return canOpen;
  }

  function forceClose() {
    isOpen.value = false;
    void overrides.onClose?.();
  }

  function toggleOpen() {
    isOpen.value ? close() : open();
  }

  return {
    isOpen,
    close,
    open,
    forceClose,
    toggleOpen,
    triedToClose,
    triedToOpen,
  };
}
