import type { PropType, Ref } from "vue";

import { toRef, whenever } from "@vueuse/core";
import { computed, inject, reactive, ref } from "vue";

import type { Color } from "@/lib/components/types";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type {
  EventsCallback,
  ValidationRule,
  ValidationRuleBase,
} from "@/lib/validation/validation.types";

import { useModel } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
  propsToDefaults,
} from "@/lib/composables/componentComposable";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { aggressive } from "@/lib/validation/events";
import { required } from "@/lib/validation/rules/native";
import { _validationObserverKey } from "@/lib/validation/ValidationObserver/useValidationObserver";
import { useListeners } from "@/lib/validation/ValidationProvider/useListeners";

import type { ValidateEvent } from "./useValidation";

import { useObserverInject } from "./useObserverInject";
import { useRules } from "./useRules";
import { useValidation } from "./useValidation";

const useValidationProviderScoped = <Value = unknown>() =>
  propsDefinition({
    value: {
      type: [String, Number, Array, Boolean, Object] as PropType<Value>,
      required: true,
    },
    required: { type: Boolean, default: false, required: false },
    errorLabel: { type: String, required: false },
    label: { type: String, required: false },
    name: { type: String, required: true },
    rules: {
      type: Array as PropType<ValidationRuleBase<NoInfer<Value>>[]>,
      default: () => [],
    },
    validationEvents: {
      type: [Array, Function] as PropType<
        EventsCallback<NoInfer<Value>> | string[]
      >,
      default: () => aggressive,
      required: false,
    },
    loading: { type: Boolean, default: false, required: false },
    validationTrigger: {
      type: Function as PropType<
        ((validateEvent: ValidateEvent) => void) | null
      >,
      default: null,
      required: false,
    },
  });

const useValidationProviderProps = useValidationProviderScoped;

const useValidationProviderEmits = emitsDefinition([
  "validationError",
  "update:loading",
]);

type UseValidationProviderProps<Value = unknown> = DefineProps<
  ReturnType<typeof useValidationProviderProps<Value>>
> & { value: Value };
interface UseValidationProviderEmits {
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "update:loading", value: boolean): void;
}

function mergeErrorProps(
  ...errorProps: {
    color?: Color;
    component?: string;
    error: string | null;
  }[]
) {
  return computed(() => errorProps.find(({ error }) => !!error));
}

function getError<Value>(
  failedRule: Ref<ValidationRule<Value> | null>,
  serverSideError: Ref<string>,
) {
  return computed(() => {
    if (!failedRule.value) {
      return serverSideError.value || null;
    }
    if (!failedRule.value.blocking && serverSideError.value) {
      return serverSideError.value;
    }
    return failedRule.value.message;
  });
}

function useValidationProvider<Value>(
  propsParam: Partial<UseValidationProviderProps<Value>> &
    Pick<UseValidationProviderProps<Value>, "name" | "rules" | "value">,
  emit: UseValidationProviderEmits = () => null,
) {
  const props = mergeReactive(
    propsToDefaults(useValidationProviderProps<Value>()),
    propsParam,
  ) as UseValidationProviderProps<Value>;
  const modelValue = toRef(() => props.value);
  const loading = useModel("loading", props, emit, { local: true });

  const failedRule = ref<ValidationRule<Value> | null>(null);
  const errorColor = computed(() => failedRule.value?.color);
  const errorComponent = computed(() => failedRule.value?.component);

  const validationObserver = inject(_validationObserverKey, null);

  const serverSideError = computed(() => {
    return validationObserver?.serverSideErrors.value[props.name]?.[0] ?? "";
  });

  const error = getError(failedRule, serverSideError);

  whenever(error, () => {
    emit("validationError", { error: error.value, name: props.name });
  });

  const { allRules, blockingRules } = useRules<Value>(props, error);

  const {
    validatedRules,
    validate,
    validateAll,
    validateBlocking,
    validateEvent,
    validateSilent,
  } = useValidation(modelValue, loading, failedRule, blockingRules, allRules);

  props.validationTrigger?.(validateEvent);
  /*
    State
   */
  const blockingValidated = computed(() => {
    return blockingRules.value.every(({ name }) =>
      validatedRules.value.includes(name),
    );
  });

  const allValidated = computed(() => {
    return validatedRules.value.length === allRules.value.length;
  });

  const hasErrors = computed(() => {
    return !!failedRule.value || !!serverSideError.value;
  });

  const hasBlockingErrors = computed(() => {
    return !!failedRule.value?.blocking || !!serverSideError.value;
  });

  const displayValid = computed(() => {
    return (
      required<Value>().validate(modelValue.value) &&
      allValidated.value &&
      !hasErrors.value
    );
  });

  const displayInvalid = computed(() => hasBlockingErrors.value);

  const initiallyValid = ref(false);
  async function updateInitiallyValid() {
    initiallyValid.value = !(await validateSilent(blockingRules.value));
  }
  void updateInitiallyValid();

  const validationPassed = computed(() => {
    return (
      (blockingValidated.value || initiallyValid.value) &&
      !hasBlockingErrors.value
    );
  });

  const validationNotPassed = computed(() => {
    return (
      (!blockingValidated.value && !initiallyValid.value) ||
      hasBlockingErrors.value
    );
  });

  function reset() {
    validatedRules.value = [];
    failedRule.value = null;
  }

  useObserverInject(
    toRef(props, "name"),
    modelValue,
    validateAll,
    validationPassed,
    validationNotPassed,
    reset,
  );

  const validationListeners = useListeners(modelValue, validateEvent, allRules);

  return {
    valid: displayValid,
    invalid: displayInvalid,
    loading,
    error,
    failedRule,
    errorComponent,
    allRules,
    errorProps: reactive({
      error,
      color: errorColor,
      component: errorComponent,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: toRef<any>(() => failedRule.value?.params),
    }),
    inputProps: reactive({
      valid: displayValid,
      loading,
      invalid: displayInvalid,
      color: errorColor,
    }),
    validationListeners,
    validateSilent,
    validate,
    validateAll,
    validateEvent,
    validateBlocking,
    reset,
  };
}

export type {
  UseValidationProviderEmits,
  UseValidationProviderProps,
  ValidateEvent,
};
export {
  getError,
  mergeErrorProps,
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderProps,
  useValidationProviderScoped,
};
