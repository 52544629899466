import type { PropType } from "vue";

import { toRef } from "@vueuse/core";
import { computed, reactive } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";
import { useI18n } from "@/lib/composables/useI18n";

const scoped = propsDefinition({
  invalid: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  label: { type: String, required: false },
  required: { type: Boolean, default: false },
  showRequiredType: {
    type: String as PropType<"none" | "optional" | "required">,
    default: "required",
  },
  size,
});

const props = propsDefinition({
  tag: { type: String, default: "label" },
  for: { type: String, required: false },
  labelId: { type: String, required: false },
  ...scoped,
});

type UseLabelProps = DefineProps<typeof props>;

function use(props: UseLabelProps) {
  const { tr } = useI18n();

  const suffix = computed(() => {
    if (props.showRequiredType === "optional" && !props.required) {
      return tr("input.optional");
    }

    if (props.showRequiredType === "required" && props.required) {
      return "*";
    }

    return null;
  });

  return {
    wrapper: {
      props: reactive({
        for: toRef(() => props.for),
        id: toRef(() => props.labelId),
      }),
    },
    size: toRef(() => props.size),
    tag: toRef(() => props.tag),
    label: toRef(() => props.label),
    suffix,
    disabled: toRef(() => props.disabled),
    invalid: toRef(() => props.invalid),
  };
}

export type { UseLabelProps };
export { props as useLabelProps, scoped as useLabelScoped };
export default { scoped, props, use };
