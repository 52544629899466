import { toReactive, toRef } from "@vueuse/core";
import { reactive } from "vue";

import type { UseCheckboxRadioGroupEmits } from "@/lib/components/logic/atoms/useCheckboxRadioGroup";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import {
  useCheckboxRadioGroupEmits,
  useCheckboxRadioGroupScoped,
} from "@/lib/components/logic/atoms/useCheckboxRadioGroup";
import {
  useDescriptionIf,
  useDescriptionScoped,
} from "@/lib/components/logic/atoms/useDescription";
import { useLabelScoped } from "@/lib/components/logic/atoms/useLabel";
import {
  useSubtextIf,
  useSubtextScoped,
} from "@/lib/components/logic/atoms/useSubtext";
import { useTooltipScoped } from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useLabelScoped,
  ...useTooltipScoped,
  ...useDescriptionScoped,
  ...useValidationProviderScoped(),
  ...useCheckboxRadioGroupScoped,
  ...useSubtextScoped,
});

const emits = emitsDefinition([
  ...useCheckboxRadioGroupEmits,
  ...useValidationProviderEmits,
]);

type UseButtonGroupProps = DefineProps<typeof props>;
type UseButtonGroupEmits = UseCheckboxRadioGroupEmits &
  UseValidationProviderEmits;

function use(props: UseButtonGroupProps, emit: UseButtonGroupEmits) {
  const { validationListeners, error, errorComponent, errorProps } =
    useValidationProvider(props, emit);

  const { label, tooltip, description, subtext } = useAutoI18n(
    toRef(props, "name"),
    reactivePick(props, ["label", "tooltip", "description", "subtext"]),
  );

  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  const container = {
    props: reactive({ "aria-describedby": describedBy }),
  };

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabelScoped), {
      tag: "legend",
      label,
    }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltipScoped), {
      tooltipId: toRef(() => ids.tooltip),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescriptionIf(props),
    props: mergeReactive(pickProps(props, useDescriptionScoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const checkboxRadioGroup = {
    props: pickProps(props, useCheckboxRadioGroupScoped),
    on: mergeListeners(
      reEmit(emit, useCheckboxRadioGroupEmits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtextIf(props),
    props: mergeReactive(pickProps(props, useSubtextScoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    container,
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    checkboxRadioGroup,
    subtextAtom,
    error,
    errorComponent,
    errorProps,
  };
}

export type { UseButtonGroupProps };
export default {
  props,
  emits,
  use,
};
export { emits as useButtonGroupEmits, props as useButtonGroupProps };
