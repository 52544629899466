import { isObject } from "radash";

import type { useI18n } from "@/lib/composables/useI18n";
import type {
  ValidationRuleBase,
  ValidationRuleDefinition,
  ValidationRuleOverrides,
} from "@/lib/validation/validation.types";

function paramsToMessageParams<Params>(
  fieldLabel: string,
  params: Params,
  ruleName: string,
) {
  if (isObject(params)) {
    return { _field_: fieldLabel, ...params };
  }
  return { _field_: fieldLabel, [ruleName]: String(params) };
}

function stringMessage(
  message: string,
  messageParams: { [key: string]: string; _field_: string },
) {
  const regex = new RegExp(
    Object.keys(messageParams)
      .map((param) => `{${param}}`)
      .join("|"),
    "g",
  );
  return message.replace(
    regex,
    (matched) =>
      messageParams[matched.substring(1, matched.length - 1)] ?? matched,
  );
}

function autoTranslateMessage(
  fieldName: string,
  messageParams: { [key: string]: string; _field_: string },
  ruleName: string,
  { tr, trOptional }: Pick<ReturnType<typeof useI18n>, "tr" | "trOptional">,
) {
  return (
    trOptional(`fields.${fieldName}.validation.${ruleName}`, messageParams) ??
    tr(`validation.${ruleName}`, messageParams)
  );
}

function getMessageFunction<ModelValue, Params>(
  rule: ValidationRuleDefinition<ModelValue, Params>,
  params: Params,
): ValidationRuleBase<ModelValue>["message"] {
  const message = rule.message;

  return (fieldLabel, value, fieldName, i18n) => {
    if (typeof message === "function") {
      return message(fieldLabel, value, fieldName, params);
    }

    const messageParams = paramsToMessageParams(fieldLabel, params, rule.name);

    if (message) {
      return stringMessage(message, messageParams);
    }

    return autoTranslateMessage(fieldName, messageParams, rule.name, i18n);
  };
}

function defineRule<Value, Params, Return extends Promise<boolean> | boolean>(
  rule: ValidationRuleDefinition<Value, Params, Return>,
) {
  return <ModelValue extends Value | null | undefined>(
    params?: Params,
    overrides?: ValidationRuleOverrides<ModelValue, Params>,
  ): ValidationRuleBase<ModelValue, Params, Return> => {
    const finalRule: ValidationRuleDefinition<ModelValue, Params, Return> =
      overrides ? { ...rule, ...overrides } : rule;

    return {
      ...finalRule,
      validate: (value: ModelValue | null | undefined) =>
        rule.validate(value, params as Params),
      message: getMessageFunction(finalRule, params as Params),
      params: params as Params,
    };
  };
}

export { defineRule };
