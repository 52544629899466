import {
  faCheck,
  faExclamationCircle,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { toRef } from "@vueuse/core";
import { computed, type PropType, reactive } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SIconDefinition } from "@/lib/icons/fontAwesome.types";

import { size } from "@/lib/components/logic/atoms/input/props";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  showValidState: { type: Boolean, default: true },
  prefixIcon: {
    type: Object as PropType<SIconDefinition | null>,
    required: false,
  },
  prefixIconButton: { type: Boolean, default: false },
  suffixIcon: {
    type: Object as PropType<SIconDefinition | null>,
    required: false,
  },
  suffixIconButton: { type: Boolean, default: false },
  size,
});

const props = propsDefinition({
  ...scoped,
  valid: { type: Boolean, default: false },
  invalid: { type: Boolean, default: false },
  color: { type: String, default: "gray" },
  location: {
    type: String as PropType<"prefix" | "suffix">,
    required: false,
  },
  icon: {
    type: Object as PropType<SIconDefinition | null>,
    required: false,
  },
});

const emits = emitsDefinition(["clickPrefixIcon", "clickSuffixIcon"]);

type UseInputIconAtomProps = DefineProps<typeof props>;
type UseInputIconAtomEmits = (
  event: "clickPrefixIcon" | "clickSuffixIcon",
) => void;

function use(props: UseInputIconAtomProps, emit: UseInputIconAtomEmits) {
  const suffixIcon = computed(() => {
    if (props.loading) {
      return faSpinnerThird;
    }
    if (props.suffixIcon) {
      return props.suffixIcon;
    }
    if (props.valid && props.showValidState) {
      return faCheck;
    }
    if (props.invalid) {
      return faExclamationCircle;
    }
    return null;
  });

  const spin = computed(() => {
    return props.location === "suffix" && props.loading;
  });

  const internalColor = computed(() => {
    if (props.location === "suffix") {
      return props.valid && props.showValidState ? "success" : props.color;
    }
    return props.color;
  });

  const icon = computed(() => {
    if (props.icon) {
      return props.icon;
    }
    if (props.location === "prefix") {
      return props.prefixIcon;
    }
    return suffixIcon.value;
  });

  const isClickable = computed(() => {
    return (
      (props.location === "prefix" && props.prefixIconButton) ||
      (props.location === "suffix" && props.suffixIconButton)
    );
  });

  function click() {
    if (!isClickable.value || props.disabled) {
      return;
    }
    if (props.location === "prefix") {
      emit("clickPrefixIcon");
    }
    if (props.location === "suffix") {
      emit("clickSuffixIcon");
    }
  }

  const disabled = computed(() => isClickable.value && props.disabled);

  const tag = computed(() => (isClickable.value ? "button" : "span"));
  const type = computed(() => (isClickable.value ? "button" : null));

  return {
    wrapper: {
      props: reactive({
        type,
        disabled,
      }),
      on: { click },
    },
    icon,
    tag,
    location: toRef(props, "location"),
    spin,
    color: internalColor,
    size: toRef(props, "size"),
    isClickable,
    disabled,
  };
}

export type { UseInputIconAtomEmits, UseInputIconAtomProps };
export {
  emits as useInputIconAtomEmits,
  props as useInputIconAtomProps,
  scoped as useInputIconAtomScoped,
};
export default { scoped, props, emits, use };
