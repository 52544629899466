import { toRef } from "@vueuse/core";
import { computed, reactive } from "vue";

import type { UseCheckboxRadioInputEmits } from "@/lib/components/logic";
import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  primitiveOrArrayValue,
  size,
} from "@/lib/components/logic/atoms/input/props";
import { useCheckboxRadioEmits } from "@/lib/components/logic/atoms/useCheckboxRadio";
import { useModel } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import {
  type OptionValue,
  useOptionsStore,
  useOptionsStoreScoped,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

const scoped = propsDefinition({
  ...useOptionsStoreScoped,
  size,
  value: primitiveOrArrayValue,
  multiple: { type: Boolean, default: false },
  imageContain: { type: Boolean, default: false },
  inline: { type: Boolean, default: false },
  outlined: { type: Boolean, default: false },
});

const props = scoped;

const emits = emitsDefinition(["input", ...useCheckboxRadioEmits]);

type UseCheckboxRadioGroupProps = DefineProps<typeof props>;
type UseCheckboxRadioGroupEmits = UseCheckboxRadioInputEmits &
  ((event: "input", value: PrimitiveOrArrayValue) => void);

function use(
  props: UseCheckboxRadioGroupProps,
  emit: UseCheckboxRadioGroupEmits,
) {
  const modelValue = useModel("value", props, emit, { local: true });

  const optionsStore = useOptionsStore(modelValue, props);

  const items = computed(() => {
    return optionsStore.items.value.map((item) => ({
      key: item.id,
      props: reactive({
        size: props.size,
        outlined: props.outlined,
        imageContain: props.imageContain,
        ...item,
      }),
      on: {
        ...reEmit(emit, useCheckboxRadioEmits),
        input: (
          checked: boolean,
          value: OptionValue | OptionValue[] = item.value,
        ) => optionsStore.setChecked(checked, value),
      },
    }));
  });

  return {
    optionsStore,
    items,
    inline: toRef(() => props.inline),
    outlined: toRef(() => props.outlined),
  };
}

export {
  emits as useCheckboxRadioGroupEmits,
  props as useCheckboxRadioGroupProps,
  scoped as useCheckboxRadioGroupScoped,
};
export type { UseCheckboxRadioGroupEmits };
export default { scoped, props, emits, use };
