<template>
  <div class="s-checkbox">
    <slot name="checkboxRadio" v-bind="checkboxRadio">
      <s-checkbox-radio v-bind="checkboxRadio.props" v-on="checkboxRadio.on">
        <template v-for="(_index, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </s-checkbox-radio>
    </slot>
    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent?.toString()" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import SCheckboxRadio from "@/lib/components/atoms/checkbox-radio/SCheckboxRadio.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import useCheckbox from "@/lib/components/logic/molecules/useCheckbox";

const props = defineProps(useCheckbox.props);
const emit = defineEmits(useCheckbox.emits);

/*defineSlots*/

const { checkboxRadio, subtextAtom, error, errorComponent, errorProps } =
  useCheckbox.use(props, emit);
</script>
