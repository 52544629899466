<template>
  <s-button-icon
    v-if="props.tooltip"
    v-bind="props"
    action-type="quaternary"
    :icon="faCircleInfo"
    size="inline"
  />
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";

import SButtonIcon from "@/lib/components/atoms/button/SButtonIcon.vue";
import { useTooltipProps } from "@/lib/components/logic/atoms/useTooltip";

const props = defineProps(useTooltipProps);
</script>
