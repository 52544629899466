<template>
  <s-text-input v-bind="textInput.props" v-on="textInput.on">
    <template v-for="(_index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </s-text-input>
</template>

<script setup lang="ts">
import useNumberInputPreset from "@/lib/components/logic/molecules/useNumberInputPreset";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";

const props = defineProps(useNumberInputPreset.props);
const emit = defineEmits(useNumberInputPreset.emits);

const { textInput } = useNumberInputPreset.use(props, emit);
</script>
