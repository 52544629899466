import type { PropType } from "vue";

import type { Color, Size } from "@/lib/components/types";
import type { InputValue } from "@/lib/helpers/types";

const type = {
  type: String as PropType<"email" | "number" | "password" | "tel" | "text">,
  default: "text",
  validator: (value: string) =>
    ["text", "email", "password", "tel", "number"].includes(value),
};

type PrimitiveOrArrayValue = InputValue<
  (boolean | number | string)[] | boolean | number | string
>;
const primitiveOrArrayValue = {
  type: [String, Number, Array, Boolean] as PropType<PrimitiveOrArrayValue>,
  default: null,
};

const size = {
  type: String as PropType<Size>,
  default: "md",
  validator: (propValue: string) => ["lg", "md", "sm"].includes(propValue),
};

const decimals = { type: [String, Number, Boolean], default: false };
type Decimals = boolean | number | string;

const color = {
  type: String as PropType<Color>,
  default: "warning",
  validator: (colorValue: string) =>
    ["primary", "accent", "success", "warning", "danger", "gray"].includes(
      colorValue,
    ),
};

const characterCounter = { type: [Number, String, Boolean], default: false };

export type { Decimals, PrimitiveOrArrayValue };
export { characterCounter, color, decimals, primitiveOrArrayValue, size, type };
