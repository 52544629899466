<template>
  <s-text-input v-bind="textInput.props" v-on="textInput.on">
    <template v-for="(_index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
    <template #suggestion="{ params: { suggestion, apply } }">
      <s-alert class="s-email__suggestion" color="gray">
        {{ $t("fields.email.didYouMean") }}
        <s-button
          action-type="quaternary"
          class="s-email__suggestion-button"
          size="inline"
          @click="apply"
        >
          {{ suggestion }}
        </s-button>
      </s-alert>
    </template>
  </s-text-input>
</template>

<script setup lang="ts">
import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import SButton from "@/lib/components/atoms/button/SButton.vue";
import useEmail from "@/lib/components/logic/molecules/useEmail";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";

const props = defineProps(useEmail.props);
const emit = defineEmits(useEmail.emits);

const { textInput } = useEmail.use(props, emit);
</script>

<style lang="postcss">
.s-email__suggestion {
  @apply mt-2;

  &-button {
    @apply ml-0;
  }
}
</style>
