<template>
  <s-input-wrapper-atom
    v-bind="inputWrapperAtom.props"
    v-on="inputWrapperAtom.on"
  >
    <s-input-icon-atom
      v-bind="inputIconAtom.props"
      location="prefix"
      v-on="inputIconAtom.on"
    />
    <s-input-field-atom
      v-bind="{ ...inputFieldAtom.props, ...$attrs }"
      v-on="inputFieldAtom.on"
    />
    <slot name="suffixIcon">
      <s-input-icon-atom
        v-bind="inputIconAtom.props"
        location="suffix"
        v-on="inputIconAtom.on"
      />
    </slot>
  </s-input-wrapper-atom>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import SInputFieldAtom from "@/lib/components/atoms/input/SInputFieldAtom.vue";
import SInputIconAtom from "@/lib/components/atoms/input/SInputIconAtom.vue";
import SInputWrapperAtom from "@/lib/components/atoms/input/wrapper/SInputWrapperAtom.vue";
import useInputAtom from "@/lib/components/logic/atoms/input/useInputAtom";

const props = defineProps(useInputAtom.props);

const emit = defineEmits(useInputAtom.emits);

const { inputWrapperAtom, inputFieldAtom, inputIconAtom } = useInputAtom.use(
  props,
  emit,
);
</script>
