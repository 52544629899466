<template>
  <div class="s-text-input">
    <s-label-atom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />
    <s-description
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />
    <s-input-atom v-bind="inputAtom.props" v-on="inputAtom.on" />
    <s-character-counter
      v-if="!error && characterCounterAtom.if.value"
      v-bind="characterCounterAtom.props"
    />
    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SCharacterCounter from "@/lib/components/atoms/character-counter/SCharacterCounter.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SInputAtom from "@/lib/components/atoms/input/SInputAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import useTextInput from "@/lib/components/logic/molecules/useTextInput";

const props = defineProps(useTextInput.props);
const emit = defineEmits(useTextInput.emits);

const {
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  inputAtom,
  characterCounterAtom,
  subtextAtom,
  error,
  errorComponent,
  errorProps,
} = useTextInput.use(props, emit);
</script>

<style lang="postcss">
.s-text-input {
  @apply flex flex-col;
}
</style>
