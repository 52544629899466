import type { PropType } from "vue";

import { useEventListener } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile } from "@/lib/composables/useFileUpload";

import { useModel } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const props = propsDefinition({
  images: { type: Array as PropType<SFile[]>, default: () => [] },
  value: { type: String, required: false },
});

const emits = emitsDefinition(["input"]);

type UseLightboxProps = DefineProps<typeof props>;
type UseLightboxEmits = (
  event: "input",
  value: UseLightboxProps["value"],
) => void;

function use(props: UseLightboxProps, emit: UseLightboxEmits) {
  const model = useModel("value", props, emit);

  const activeImage = computed(() => {
    return props.images.find((image) => image.uuid === model.value);
  });

  const activeIndex = computed(() => {
    return props.images.findIndex((image) => image.uuid === model.value);
  });

  function goToNext() {
    model.value = (
      props.images[activeIndex.value + 1] ?? props.images[0]
    )?.uuid;
  }

  function goToPrevious() {
    model.value = (
      props.images[activeIndex.value - 1] ??
      props.images[props.images.length - 1]
    )?.uuid;
  }

  function close() {
    model.value = undefined;
  }

  function keydown({ key }: KeyboardEvent) {
    if (!model.value) {
      return;
    }
    switch (key) {
      case "ArrowLeft":
        goToPrevious();
        break;
      case "ArrowRight":
        goToNext();
        break;
      case "Escape":
        close();
        break;
      default:
      // Do nothing
    }
  }

  function containerClick(event: MouseEvent) {
    if (event.target !== event.currentTarget) {
      return;
    }
    close();
  }

  useEventListener(document, "keydown", keydown);

  return {
    input: {
      on: {
        click: containerClick,
      },
    },
    activeImage,
  };
}

export default { props, emits, use };

export type { UseLightboxProps };
export { emits as useLightboxEmits, props as useLightboxProps };
