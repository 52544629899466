<template>
  <s-input-wrapper-atom v-bind="inputWrapperAtom.props">
    <textarea
      :ref="input.ref"
      v-bind="input.props"
      class="s-textarea-atom"
      :class="[`s-textarea-atom--size-${size}`]"
      v-on="input.on"
    />
  </s-input-wrapper-atom>
</template>

<script setup lang="ts">
import SInputWrapperAtom from "@/lib/components/atoms/input/wrapper/SInputWrapperAtom.vue";
import useTextareaAtom from "@/lib/components/logic/atoms/useTextareaAtom";

const props = defineProps(useTextareaAtom.props);
const emit = defineEmits(useTextareaAtom.emits);

const { inputWrapperAtom, size, input } = useTextareaAtom.use(props, emit);
</script>

<style lang="postcss">
.s-textarea-atom {
  @apply block w-full min-w-full rounded bg-transparent text-gray-800;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &--size {
    &-lg {
      @apply px-6 py-4 text-lg;
    }

    &-md {
      @apply px-5 py-3 text-base;
    }

    &-sm {
      @apply px-4 py-2 text-base;
    }
  }

  &:focus {
    @apply outline-none;
  }

  &::placeholder {
    @apply text-gray-400;
  }

  &:disabled {
    @apply text-gray-600;

    &::placeholder {
      @apply text-gray-300;
    }
  }
}
</style>
