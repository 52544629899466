import type { PropType } from "vue";

import { faEuroSign } from "@fortawesome/pro-regular-svg-icons";
import { reactiveComputed, toRef } from "@vueuse/core";
import { omit } from "radash";

import type { UseTextInputEmits } from "@/lib/components/logic/molecules/useTextInput";
import type { DefineProps } from "@/lib/composables/componentComposable";

import useTextInput from "@/lib/components/logic/molecules/useTextInput";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { faSolvariCredit } from "@/lib/icons/fontAwesome";

const props = propsDefinition({
  ...omit(useTextInput.props, ["type"]),
  preset: {
    type: String as PropType<string | null>,
    default: null,
  },
});

const emits = emitsDefinition(useTextInput.emits);

type UseNumberInputProps = DefineProps<typeof props>;
type UseNumberInputEmits = UseTextInputEmits;

function use(props: UseNumberInputProps, emit: UseNumberInputEmits) {
  const preset = toRef(props, "preset");

  const presetProps = reactiveComputed(() => {
    if (preset.value === "euro") {
      return {
        decimals: 2,
        decimalsFill: true,
        prefixIcon: faEuroSign,
      };
    } else if (preset.value === "credit") {
      return {
        decimals: 2,
        decimalsFill: true,
        prefixIcon: faSolvariCredit,
      };
    }
    return {};
  });

  return {
    textInput: {
      props: mergeReactive(props, { type: "number" as const }, presetProps),
      on: reEmit(emit, useTextInput.emits),
    },
  };
}

export default { props, emits, use };
