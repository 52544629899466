<template>
  <transition v-bind="$attrs" v-on="$listeners">
    <slot />
  </transition>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="postcss">
.fade {
  &-enter-active,
  &-leave-active {
    @apply transition-opacity;
  }

  &-enter,
  &-leave-to {
    @apply opacity-0;
  }
}
</style>
