import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import useProgress from "@/lib/components/logic/atoms/useProgress";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";
import { mergeReactive } from "@/lib/helpers/reactivity";

const props = propsDefinition({
  ...useProgress.props,
  value: {
    type: Number,
    default: 1,
  },
});
const emit = emitsDefinition(["input"]);

type UseStepperMinimalProps = DefineProps<typeof props>;
type UseStepperEmits = (event: "input", value: number | undefined) => void;

function use(props: UseStepperMinimalProps, emit: UseStepperEmits) {
  const modelValue = useModel("value", props, emit);
  const max = toRef(() => props.max);

  function previousStep() {
    if (modelValue.value > 0) {
      modelValue.value--;
    }
  }

  const showPreviousButton = computed(() => modelValue.value > 1);

  const progressAtom = {
    props: mergeReactive(props, { value: modelValue }),
  };

  return {
    progressAtom,
    currentStep: modelValue,
    max,
    previousStep,
    showPreviousButton,
  };
}

export default { props, use, emit };
