<template>
  <component
    :is="tag"
    class="s-button"
    :class="{
      [`s-button--action-${actionType}`]: true,
      [`s-button--color-${color}`]: true,
      [`s-button--size-${size}`]: true,
      's-button--disabled': disabled,
    }"
    v-bind="buttonProps"
    @click="click"
  >
    <span v-if="prefixIcon" class="s-button__icon s-button__prefix">
      <font-awesome-icon :icon="prefixIcon" :spin="loading" />
    </span>
    <slot />
    <span v-if="suffixIcon" class="s-button__icon s-button__suffix">
      <font-awesome-icon :icon="suffixIcon" :spin="loading" />
    </span>
  </component>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useButton from "@/lib/components/logic/atoms/useButton";

const props = defineProps(useButton.props);
const emit = defineEmits(useButton.emits);
const {
  tag,
  buttonProps,
  actionType,
  click,
  size,
  color,
  loading,
  suffixIcon,
  prefixIcon,
  disabled,
} = useButton.use(props, emit);
</script>

<style lang="postcss">
.s-button {
  @apply inline-flex cursor-pointer items-center justify-center rounded border border-transparent text-center font-semibold no-underline transition-colors;

  &__icon {
    @apply inline-flex h-1em w-1em;
  }

  &__prefix {
    @apply mr-2;
  }

  &__suffix {
    @apply ml-2;
  }

  &--color {
    &-primary {
      --button-color-500: theme("colors.primary.700");
      --button-color-400: theme("colors.primary.600");
      --button-color-200: theme("colors.primary.200");
      --button-color-100: theme("colors.primary.100");
    }

    &-accent {
      --button-color-500: theme("colors.accent.500");
      --button-color-400: theme("colors.accent.400");
      --button-color-200: theme("colors.accent.200");
      --button-color-100: theme("colors.accent.100");
    }

    &-success {
      --button-color-500: theme("colors.success.500");
      --button-color-400: theme("colors.success.400");
      --button-color-200: theme("colors.success.200");
      --button-color-100: theme("colors.success.100");
    }

    &-warning {
      --button-color-500: theme("colors.warning.500");
      --button-color-400: theme("colors.warning.400");
      --button-color-200: theme("colors.warning.200");
      --button-color-100: theme("colors.warning.100");
    }

    &-danger {
      --button-color-500: theme("colors.danger.500");
      --button-color-400: theme("colors.danger.400");
      --button-color-200: theme("colors.danger.200");
      --button-color-100: theme("colors.danger.100");
    }

    &-gray {
      --button-color-500: theme("colors.gray.500");
      --button-color-400: theme("colors.gray.400");
      --button-color-200: theme("colors.gray.200");
      --button-color-100: theme("colors.gray.100");
    }
  }

  &--action {
    &-primary {
      @apply text-white;
      background-color: var(--button-color-500);

      &:hover {
        @apply text-white;
        background-color: var(--button-color-400);
      }
    }

    &-secondary {
      color: var(--button-color-500);
      background-color: var(--button-color-100);

      &:hover {
        @apply text-white;
        background-color: var(--button-color-400);
      }
    }

    &-tertiary {
      color: var(--button-color-500);
      border: 1px solid var(--button-color-500);

      &:hover {
        color: var(--button-color-500);
        background-color: var(--button-color-100);
        border-color: var(--button-color-400);
      }
    }

    &-quaternary {
      color: var(--button-color-500);

      &:hover {
        color: var(--button-color-400);
        background-color: var(--button-color-100);
      }
    }

    &-link {
      color: var(--button-color-500);
      @apply underline;

      &:hover {
        color: var(--button-color-400);
      }
    }
  }

  &--size {
    &-lg {
      @apply min-h-14 px-10 py-3 text-base;
    }

    &-md {
      @apply min-h-12 px-8 py-2 text-base;
    }

    &-sm {
      @apply min-h-10 px-5 py-2 text-sm;
    }

    &-inline {
      @apply -mx-2 -my-1 inline-flex px-2 py-1;
    }
  }

  &--disabled,
  &--disabled:hover {
    @apply cursor-not-allowed border-gray-100 bg-gray-100 text-gray-500;
  }
}
</style>
