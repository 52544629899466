<template>
  <s-alert
    v-if="description"
    v-bind="containerProps"
    class="s-description"
    color="primary"
    :prefix-icon="faCircleInfo"
  >
    {{ description }}
  </s-alert>
</template>

<script setup lang="ts">
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import useDescription from "@/lib/components/logic/atoms/useDescription";

const props = defineProps(useDescription.props);
const { containerProps, description } = useDescription.use(props);
</script>

<style lang="postcss">
.s-description {
  @apply mb-2;
}
</style>
