import type { MaybeRef } from "@vueuse/core";
import type { DeepReadonly, Ref } from "vue";

import { toValue } from "@vueuse/core";
import { sort } from "radash";
import { computed } from "vue";

import type { OptionItem } from "@/lib/composables/useOptionsStore/useOptionsStore";

import { propsDefinition } from "@/lib/composables/componentComposable";
import { escapeRegExp } from "@/lib/helpers/regex";
import { compare } from "@/lib/helpers/stringSimilarity";

const useOptionsStoreFilterScoped = propsDefinition({
  filter: { type: String, default: "" },
  disableFilter: { type: Boolean, default: false },
});

function useOptionsStoreFilter(
  filter: Readonly<Ref<string>>,
  items: DeepReadonly<Ref<OptionItem[]>>,
  disableFilter: Readonly<MaybeRef<boolean>> = false,
) {
  const filterRegex = computed(
    () => new RegExp(`(${escapeRegExp(filter.value)})`, "gi"),
  );

  function highlightLabel<Item extends DeepReadonly<OptionItem>>(item: Item) {
    return {
      ...item,
      label: item.label.replace(filterRegex.value, `<strong>$1</strong>`),
    };
  }

  function defaultFilter(items: DeepReadonly<OptionItem[]>) {
    const filteredItems = items
      .map((item) => ({
        ...highlightLabel(item),
        matchRating: compare(item.label, filter.value, {
          caseSensitive: false,
        }),
      }))
      .filter((item) => item.matchRating > 0.6);

    return sort<DeepReadonly<OptionItem & { matchRating: number }>>(
      filteredItems,
      (item) => item.matchRating,
      true,
    );
  }

  return computed(() => {
    if (!filter.value) {
      return items.value;
    }
    if (toValue(disableFilter)) {
      return items.value.map((item) => highlightLabel(item));
    }
    return defaultFilter(items.value);
  });
}

export { useOptionsStoreFilter, useOptionsStoreFilterScoped };
