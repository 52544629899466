import { omit } from "radash";

import type { ArgusEvent, ArgusEvents } from "@/lib/argus/argusService";
import type { MarketingParams } from "@/lib/argus/events/marketingParams";
import type { ConsentTypes } from "@/lib/components/native/cookie-popup/state";

import { argusGetSummary } from "@/lib/argus/argus.api";
import { getArgusEvents, getSessionUuid } from "@/lib/argus/client";
import { getMarketingParamsFromUrl } from "@/lib/argus/events/marketingParams";
import { getCookie } from "@/lib/helpers/cookies";
import { keysToSnakeCase } from "@/lib/helpers/utils";
import { getSentry } from "@/lib/integrations/sentry/sentry";

type Metadata = MarketingParams & {
  argusUuid: string;
  cookieConsent: ConsentTypes | null;
  currentPage: string;
  currentPageReferrer: string | null;
  domain: string;
  experiments: Record<string, number | string>;
  landingPage: string | null;
  landingPageReferrer: string | null;
  userAgent: string;
};

function getFallbackMetadata(): Metadata {
  const currentPageMarketingParams = getMarketingParamsFromUrl();
  const referrerMarketingParams = document.referrer
    ? getMarketingParamsFromUrl(new URL(document.referrer))
    : null;

  const marketingParams = Object.values(currentPageMarketingParams).some(
    Boolean,
  )
    ? currentPageMarketingParams
    : (referrerMarketingParams ?? currentPageMarketingParams);

  return {
    argusUuid: getSessionUuid() ?? "",
    domain: window.location.hostname,
    currentPage: window.location.href,
    currentPageReferrer: document.referrer || null,
    userAgent: navigator.userAgent,
    cookieConsent: getCookie("solvariCookieConsent"),
    landingPage: null,
    landingPageReferrer: null,
    experiments: {},
    ...marketingParams,
  };
}

function isEventOfType<Type extends keyof ArgusEvents>(
  event: ArgusEvent,
  type: Type,
): event is ArgusEvent<Type> {
  return event.type === type;
}

function applyPageViewEvent(
  projection: Partial<Metadata>,
  event: ArgusEvent<"page_view">,
) {
  if (event.data.pageType === "referrer") {
    if (!projection.landingPage) {
      projection.landingPageReferrer = event.data.url;
    }
    projection.currentPageReferrer = event.data.url;
    return;
  }

  if (!projection.landingPage) {
    projection.landingPage = event.data.url;
  } else {
    projection.currentPageReferrer = projection.currentPage;
  }

  projection.domain = new URL(event.data.url).hostname;
  projection.currentPage = event.data.url;
}

function getClientSideArgusProjection(
  events: ArgusEvent[] | null = getArgusEvents(),
) {
  if (!events?.length) {
    return getFallbackMetadata();
  }
  return events.reduce((projection: Metadata, event) => {
    if (isEventOfType(event, "init")) {
      projection.userAgent = event.data.userAgent;
    }

    if (isEventOfType(event, "page_view")) {
      applyPageViewEvent(projection, event);
    }

    if (isEventOfType(event, "marketing_params")) {
      projection = { ...projection, ...event.data };
    }

    if (isEventOfType(event, "cookie_consent")) {
      projection.cookieConsent = event.data.consentTypes;
    }

    if (isEventOfType(event, "google_optimize")) {
      projection.experiments[event.data.experimentId] = event.data.variantId;
    }

    return projection;
  }, getFallbackMetadata());
}

async function getMetadataFromArgus() {
  const uuid = getSessionUuid();
  if (!uuid) {
    return getClientSideArgusProjection();
  }

  const response = await argusGetSummary(uuid);
  if (response instanceof Error) {
    getSentry().captureException(response, { tags: { project: "argus-fe" } });
    return getClientSideArgusProjection();
  }

  return { argusUuid: response.uuid, ...omit(response, ["uuid"]) };
}

async function getMetadataFromArgusSnakeCase() {
  return keysToSnakeCase(await getMetadataFromArgus());
}

export type { Metadata };
export {
  getClientSideArgusProjection,
  getMetadataFromArgus,
  getMetadataFromArgusSnakeCase,
};
