import { toReactive, toRef } from "@vueuse/core";
import { reactive } from "vue";

import type { UseCheckboxRadioEmits } from "@/lib/components/logic/atoms/useCheckboxRadio";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import {
  useCheckboxRadioEmits,
  useCheckboxRadioProps,
} from "@/lib/components/logic/atoms/useCheckboxRadio";
import {
  useSubtextIf,
  useSubtextScoped,
} from "@/lib/components/logic/atoms/useSubtext";
import { useDescribedBy } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useSubtextScoped,
  ...useValidationProviderScoped(),
  ...useCheckboxRadioProps,
  value: { type: Boolean, default: false },
});

const emits = emitsDefinition([
  ...useCheckboxRadioEmits,
  ...useValidationProviderEmits,
]);

type UseCheckboxProps = DefineProps<typeof props>;
type UseCheckboxEmits = UseCheckboxRadioEmits & UseValidationProviderEmits;

function use(props: UseCheckboxProps, emit: UseCheckboxEmits) {
  const { label, tooltip, subtext } = useAutoI18n(
    toRef(props, "name"),
    reactivePick(props, ["label", "tooltip", "subtext"]),
  );

  const { describedBy, ids } = useDescribedBy(reactive({ subtext }));

  const { error, errorComponent, errorProps, validationListeners } =
    useValidationProvider(props, emit);

  const checkboxRadio = {
    props: mergeReactive(pickProps(props, useCheckboxRadioProps), {
      tooltip,
      describedBy,
      checked: toRef(() => props.value),
      value: toRef(() => props.name),
      type: "checkbox",
      label,
    }),
    on: mergeListeners(
      reEmit(emit, useCheckboxRadioEmits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtextIf(props),
    props: mergeReactive(pickProps(props, useSubtextScoped), {
      subtext,
      subtextId: toRef(ids, "subtext"),
    }),
  };

  return {
    checked: toRef(props, "value"),
    checkboxRadio,
    error,
    errorComponent,
    errorProps,
    subtextAtom,
  };
}

export { emits as useCheckboxEmits, props as useCheckboxProps };
export default { props, emits, use };
