<template>
  <component
    :is="tag"
    v-if="icon"
    v-bind="wrapper.props"
    class="s-input-icon-atom"
    :class="{
      [`s-input-icon-atom--${location}`]: true,
      [`s-input-icon-atom--${color}`]: !!color,
      [`s-input-icon-atom--size-${size}`]: true,
      's-input-icon-atom--spin': spin,
      's-input-icon-atom--clickable': isClickable,
      's-input-icon-atom--disabled': disabled,
    }"
    v-on="wrapper.on"
  >
    <font-awesome-icon class="s-input-icon-atom__icon" :icon="icon" />
  </component>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useInputIconAtom from "@/lib/components/logic/atoms/input/useInputIconAtom";

const props = defineProps(useInputIconAtom.props);
const emit = defineEmits(useInputIconAtom.emits);

const { wrapper, icon, tag, color, size, spin, isClickable, disabled } =
  useInputIconAtom.use(props, emit);
</script>

<style lang="postcss">
.s-input-icon-atom {
  @apply flex flex-shrink-0 items-center justify-center transition-text;

  &__icon {
    @apply transition-transform;
  }

  &--size {
    &-lg {
      @apply w-14;

      .s-input-icon-atom__icon {
        @apply h-5 w-5;
      }
    }

    &-md {
      @apply w-12;

      .s-input-icon-atom__icon {
        @apply h-4 w-4;
      }
    }

    &-sm {
      @apply w-10;

      .s-input-icon-atom__icon {
        @apply h-4 w-4;
      }
    }
  }

  &--clickable {
    @apply cursor-pointer;
  }

  &--disabled {
    @apply cursor-auto;
  }

  &--spin {
    @apply animate-spin;
  }

  &--gray {
    @apply text-gray-700;
  }

  &--primary {
    @apply text-primary-700;
  }

  &--accent {
    @apply text-accent-500;
  }

  &--success {
    @apply text-success-500;
  }

  &--warning {
    @apply text-warning-500;
  }

  &--danger {
    @apply text-danger-500;
  }
}
</style>
