<template>
  <div class="s-stepper-minimal">
    <s-button
      v-if="showPreviousButton"
      action-type="quaternary"
      class="s-stepper-minimal__previous"
      color="gray"
      :prefix-icon="faChevronLeft"
      size="inline"
      @click="previousStep"
      >{{ tr("fields.default.stepper.previous") }}</s-button
    >
    <label class="s-stepper-minimal__steps">
      {{ tr("fields.default.stepper.steps", { currentStep, max }) }}
    </label>
    <s-progress-bar
      class="s-stepper-minimal__progress"
      v-bind="progressAtom.props"
    />
  </div>
</template>

<script setup lang="ts">
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { SButton, SProgressBar } from "@/lib/components/atoms";
import useStepperMinimal from "@/lib/components/logic/molecules/useStepperMinimal";
import { useI18n } from "@/lib/composables/useI18n";

const { tr } = useI18n();

const props = defineProps(useStepperMinimal.props);
const emit = defineEmits(useStepperMinimal.emit);

const { progressAtom, currentStep, previousStep, showPreviousButton, max } =
  useStepperMinimal.use(props, emit);
</script>

<style scoped lang="postcss">
.s-stepper-minimal {
  @apply grid items-center justify-between gap-y-2;
  grid-template:
    "previous steps" minmax(26px, auto)
    "progress progress";

  &__previous {
    grid-area: previous;
  }
  &__steps {
    @apply block  text-center text-gray-600;
    grid-area: steps;
  }
  &__progress {
    grid-area: progress;
  }
}
</style>
