import { toReactive, toRef } from "@vueuse/core";
import { nanoid } from "nanoid";
import { reactive } from "vue";

import type { UseTextareaAtomEmits } from "@/lib/components/logic/atoms/useTextareaAtom";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import {
  useCharacterCounterIf,
  useCharacterCounterScoped,
} from "@/lib/components/logic/atoms/useCharacterCounter";
import {
  useDescriptionIf,
  useDescriptionScoped,
} from "@/lib/components/logic/atoms/useDescription";
import { useLabelScoped } from "@/lib/components/logic/atoms/useLabel";
import {
  useSubtextIf,
  useSubtextScoped,
} from "@/lib/components/logic/atoms/useSubtext";
import {
  useTextareaAtomEmits,
  useTextareaAtomScoped,
} from "@/lib/components/logic/atoms/useTextareaAtom";
import { useTooltipScoped } from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy, useModel } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useValidationProviderScoped(),
  ...useLabelScoped,
  ...useTooltipScoped,
  ...useDescriptionScoped,
  ...useCharacterCounterScoped,
  ...useSubtextScoped,
  ...useTextareaAtomScoped,
  characterCounter: { type: [Number, String, Boolean], default: 90 },
});

const emits = emitsDefinition([
  ...useTextareaAtomEmits,
  ...useValidationProviderEmits,
]);

type UseTextareaProps = DefineProps<typeof props>;
type UseTextareaEmits = UseTextareaAtomEmits & UseValidationProviderEmits;

function use(props: UseTextareaProps, emit: UseTextareaEmits) {
  const id = nanoid(10);
  const loading = useModel("loading", props, emit, { local: true });

  const { label, tooltip, description, subtext, errorLabel } = useAutoI18n(
    toRef(props, "name"),
    reactivePick(props, [
      "label",
      "tooltip",
      "description",
      "subtext",
      "errorLabel",
    ]),
  );

  const { validationListeners, error, errorComponent, errorProps } =
    useValidationProvider(mergeReactive(props, { errorLabel, loading }), emit);

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabelScoped), { for: id, label }),
  };

  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltipScoped), {
      tooltipId: toRef(() => ids.tooltip),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescriptionIf(props),
    props: mergeReactive(pickProps(props, useDescriptionScoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const textareaAtom = {
    props: mergeReactive(pickProps(props, useTextareaAtomScoped), {
      id,
      describedBy,
    }),
    on: mergeListeners(
      reEmit(emit, useTextareaAtomEmits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtextIf(props),
    props: mergeReactive(pickProps(props, useSubtextScoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  const characterCounterAtom = {
    if: useCharacterCounterIf(props),
    props: pickProps(props, useCharacterCounterScoped),
  };

  return {
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    textareaAtom,
    characterCounterAtom,
    subtextAtom,
    error,
    errorComponent,
    errorProps,
  };
}

export default { use, props, emits };

export { emits as useTextareaEmits, props as useTextareaProps };
