import type { Ref } from "vue";

import { inject, onBeforeUnmount, watch, watchEffect } from "vue";

import { _validationObserverKey } from "@/lib/validation/ValidationObserver/useValidationObserver";

function useObserverInject(
  name: Ref<Readonly<string>>,
  modelValue: Readonly<Ref<unknown>>,
  validateAll: () => Promise<void>,
  validationPassed: Ref<boolean>,
  validationNotPassed: Ref<boolean>,
  reset: () => void,
) {
  const validationObserver = inject(_validationObserverKey, null);

  watchEffect(() => {
    validationObserver?.updateProvider(name.value, {
      validateAll,
      reset,
      valid: validationPassed,
      invalid: validationNotPassed,
    });
  });

  onBeforeUnmount(() => {
    validationObserver?.deleteProvider(name.value);
  });

  watch(modelValue, () => {
    validationObserver?.resetServerSideErrors(name.value);
  });
}
export { useObserverInject };
