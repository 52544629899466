export function memoize<ArgsType extends unknown[], Result>(
  callback: (...args: ArgsType) => Promise<Result>,
): (...args: ArgsType) => Promise<Result>;
export function memoize<ArgsType extends unknown[], Result>(
  callback: (...args: ArgsType) => Result,
): (...args: ArgsType) => Result;
export function memoize<ArgsType extends unknown[], Result>(
  callback: (...args: ArgsType) => Promise<Result> | Result,
): (...args: ArgsType) => Promise<Result> | Result {
  const cache = new Map<string, Promise<Result> | Result>();

  return (...args: ArgsType) => {
    const paramString = JSON.stringify(args);
    const cachedResult = cache.get(paramString);
    if (cachedResult) {
      return cachedResult;
    }

    const resultPromise = callback(...args);
    cache.set(paramString, resultPromise);

    if (resultPromise instanceof Promise) {
      resultPromise.catch((error: unknown) => {
        cache.delete(paramString);
        throw error;
      });
    }

    return resultPromise;
  };
}
