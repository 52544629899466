import ky from "ky";
import { v4 as createUuid } from "uuid";

import type { ArgusEvent } from "@/lib/argus/argusService";
import type { ConsentTypes } from "@/lib/components/native/cookie-popup/cookiePopup";

import { getEnv } from "@/lib/helpers/solvariEnv";
import { withKyErrors } from "@/lib/integrations/ky";

interface Summary {
  cookieConsent: ConsentTypes | null;
  currentPage: string;
  currentPageReferrer: string | null;
  domain: string;
  experiments: Record<string, number | string>;
  fbclid: string | null;
  gclid: string | null;
  landingPage: string | null;
  landingPageReferrer: string | null;
  seaAdGroupId: string | null;
  seaAdId: string | null;
  seaCampaignId: string | null;
  seaClickId: string | null;
  seaPlatform: string | null;
  userAgent: string;
  utmCampaign: string | null;
  utmContent: string | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmTerm: string | null;
  uuid: string;
}

const argusGetSummary = withKyErrors(async (uuid: string) => {
  return (
    await ky
      .get(`${getEnv().network.argus.base}/v1/summarize/${uuid}`)
      .json<{ data: Summary }>()
  ).data;
});

const argusTrack = withKyErrors(
  async (events: ArgusEvent[], sessionUuid?: string | null) => {
    // Let's pretend the backend returns the sessionUuid,
    // as we only want to store it locally if the backend has saved the session.
    const uuid = sessionUuid ?? createUuid();
    await ky.post(`${getEnv().network.argus.base}/v1/track`, {
      json: { uuid, events },
    });
    return uuid;
  },
);

export { argusGetSummary, argusTrack };
