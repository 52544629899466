import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  characterCounter,
  primitiveOrArrayValue,
} from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  characterCounter,
  value: primitiveOrArrayValue,
  maxlength: { type: Number, required: false },
  disabled: { type: Boolean, default: false },
});

const props = scoped;

type UseCharacterCounterProps = DefineProps<typeof props>;

function use(props: UseCharacterCounterProps) {
  const length = computed(() => {
    if (Array.isArray(props.value)) {
      return props.value.length;
    }

    return props.value?.toString().length || 0;
  });

  const maxReached = computed(() => {
    if (!props.maxlength) {
      return false;
    }
    return length.value >= Number(props.maxlength);
  });

  const percentageOfMax = computed(
    () => (length.value / Number(props.maxlength)) * 100,
  );

  const shouldShow = computed(() => {
    if (!props.maxlength) {
      return false;
    }
    if (typeof props.characterCounter === "boolean") {
      return props.characterCounter;
    }
    return percentageOfMax.value >= Number(props.characterCounter);
  });

  return {
    length,
    maxlength: toRef(props, "maxlength"),
    maxReached,
    shouldShow,
    disabled: toRef(props, "disabled"),
  };
}

function vIf({
  maxlength,
  characterCounter,
}: Pick<UseCharacterCounterProps, "characterCounter" | "maxlength">) {
  return computed(() => !!maxlength && characterCounter !== false);
}

export default {
  use,
  props,
  scoped,
};

export {
  props as useCharacterCounterProps,
  scoped as useCharacterCounterScoped,
  vIf as useCharacterCounterIf,
};
