import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  subtext: { type: String, required: false },
});

const props = propsDefinition({
  ...scoped,
  subtextId: { type: String, required: false },
});

type UseSubtextProps = DefineProps<typeof props>;

function use(props: UseSubtextProps) {
  return {
    subtext: toRef(() => props.subtext),
    id: toRef(() => props.subtextId),
  };
}

function vIf(props: Pick<UseSubtextProps, "subtext">) {
  return computed(() => !!props.subtext);
}

export default { props, use, scoped };
export {
  props as useSubtextProps,
  scoped as useSubtextScoped,
  vIf as useSubtextIf,
};
