/*
  These helpers use Typescript to define the interface
  and automatically JSON parse the value
  
  Inspired by https://www.npmjs.com/package/typed-local-store

  Extend the interface like a so:
  declare global {
    interface LocalStorage {
      exampleKey: exampleType
    }
    interface SessionStorage {
      exampleKey: exampleType
    }
  }
 */

import type { ArgusEvent } from "@/lib/argus/argusService";

declare global {
  interface LocalStorage {
    lastVisitedPages: {
      date: string;
      image: string;
      title: string;
      url: string;
    }[];
  }

  interface SessionStorage {
    argusEvents: ArgusEvent[];
    solvariSession: string;
  }
}

class MemoryStorage implements Storage {
  private readonly storage: Map<string, string>;

  constructor() {
    this.storage = new Map<string, string>();
  }

  public get length() {
    return Array.from(this.storage.keys()).length;
  }

  public key(index: number) {
    return Array.from(this.storage.keys())[index] ?? null;
  }

  public getItem(key: string) {
    return this.storage.get(key) || null;
  }

  public setItem(key: string, value: string) {
    this.storage.set(key, value);
  }

  public removeItem(key: string) {
    this.storage.delete(key);
  }

  public clear() {
    this.storage.clear();
  }
}

class TypedStorage<StorageType> {
  private readonly storage: Storage;

  constructor(storage: "localStorage" | "sessionStorage" = "localStorage") {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Can sometimes still be null in some browsers
      if (window[storage]) {
        this.storage = window[storage];
      } else {
        this.storage = new MemoryStorage();
      }
    } catch (error) {
      this.storage = new MemoryStorage();
    }
  }

  public get length() {
    return this.storage.length;
  }

  public key(index: number) {
    return this.storage.key(index) as keyof StorageType | null;
  }

  public getItem<Key extends string & keyof StorageType>(key: Key) {
    const item = this.storage.getItem(key);

    if (!item) {
      return null;
    }

    try {
      return JSON.parse(item) as StorageType[Key];
    } catch (error) {
      return item as StorageType[Key];
    }
  }

  public setItem<Key extends string & keyof StorageType>(
    key: Key,
    value: StorageType[Key],
  ) {
    const stringValue =
      typeof value !== "string" ? JSON.stringify(value) : value;
    this.storage.setItem(key, stringValue);
  }

  public updateItem<Key extends string & keyof StorageType>(
    key: Key,
    update: (oldValue: StorageType[Key] | null) => StorageType[Key],
  ) {
    this.setItem(key, update(this.getItem(key)));
  }

  public removeItem(key: keyof StorageType) {
    this.storage.removeItem(key.toString());
  }

  public clear() {
    this.storage.clear();
  }
}

const sLocalStorage = new TypedStorage<LocalStorage>("localStorage");
const sSessionStorage = new TypedStorage<SessionStorage>("sessionStorage");

export { sLocalStorage, sSessionStorage };
