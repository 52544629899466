<template>
  <s-address-be
    v-if="props.locale === 'nl-BE' || props.locale === 'fr-BE'"
    v-bind="props"
    v-on="addressOn"
  />

  <s-address-fr-fr
    v-else-if="props.locale === 'fr-FR'"
    v-bind="props"
    v-on="addressOn"
  />

  <s-address-nl-nl
    v-else-if="props.locale === 'nl-NL'"
    v-bind="props"
    v-on="addressOn"
  />
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import type { LocaleIso } from "@/lib/helpers/locales";

import { size as sizeProp } from "@/lib/components/logic/atoms/input/props";
import SAddressBe from "@/lib/components/organisms/address/SAddressBe.vue";
import SAddressFrFr from "@/lib/components/organisms/address/SAddressFrFr.vue";
import SAddressNlNl from "@/lib/components/organisms/address/SAddressNlNl.vue";
import { reEmit } from "@/lib/composables/componentComposable";

const props = defineProps({
  availableLocales: {
    type: Array as PropType<LocaleIso[]>,
    default: () => [],
  },
  locale: { type: String as PropType<LocaleIso>, required: true },
  size: sizeProp,
  zipcode: { type: String as PropType<string | null>, default: "" },
  houseNumber: { type: String as PropType<string | null>, default: "" },
  street: { type: String as PropType<string | null>, default: "" },
  city: { type: String as PropType<string | null>, default: "" },
});

const emit = defineEmits(["blur", "focus", "update:city", "update:houseNumber", "update:locale", "update:street", "update:zipcode", "validationError"]);

const addressOn = reEmit(emit, [
  "update:locale",
  "update:zipcode",
  "update:houseNumber",
  "update:street",
  "update:city",
  "focus",
  "blur",
  "validationError",
]);
</script>
