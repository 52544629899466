import type { Ref } from "vue";
import type VueI18n from "vue-i18n";
import type { Values } from "vue-i18n";

import { computed } from "vue";
import { useI18n as useI18nLibrary } from "vue-i18n-composable";

export function useI18n() {
  const i18nInstance = useI18nLibrary();

  const tr = i18nInstance.t as (
    key: VueI18n.Path,
    values?: VueI18n.Values,
  ) => string;

  function trOptional(key: string, ...params: Values[]) {
    const translation = tr(key, ...params);
    return translation !== key ? translation : undefined;
  }

  return { ...i18nInstance, tr, trOptional };
}

/*
  Auto translation structure:
  fields: {
    fieldName: {
      label: string,
      tooltip: string,
      description: string,
      placeholder: string,
      subtext: string,
      errorLabel: string,
      validation: {
        [ruleName: string]: string,
      }
    }
  }
*/

export function useAutoI18n(
  name: Ref<Readonly<string>>,
  props: Partial<{
    description: string | null;
    errorLabel: string | null;
    label: string | null;
    placeholder: string | null;
    subtext: string | null;
    tooltip: string | null;
  }>,
) {
  const { trOptional } = useI18n();

  const label = computed(
    () => props.label ?? trOptional(`fields.${name.value}.label`) ?? name.value,
  );

  const tooltip = computed(
    () => props.tooltip ?? trOptional(`fields.${name.value}.tooltip`),
  );

  const description = computed(
    () => props.description ?? trOptional(`fields.${name.value}.description`),
  );

  const placeholder = computed(
    () => props.placeholder ?? trOptional(`fields.${name.value}.placeholder`),
  );

  const subtext = computed(
    () => props.subtext ?? trOptional(`fields.${name.value}.subtext`),
  );

  const errorLabel = computed(
    () =>
      props.errorLabel ??
      trOptional(`fields.${name.value}.errorLabel`) ??
      label.value,
  );

  return {
    label,
    tooltip,
    description,
    placeholder,
    subtext,
    errorLabel,
  };
}
