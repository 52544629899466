<template>
  <s-button-group
    v-bind="questionLogic.props"
    class="form-question"
    multiple
    v-on="{ ...gtmListeners, ...questionLogic.on }"
  >
    <template #checkboxRadio="checkboxRadio">
      <s-checkbox-radio
        v-bind="checkboxRadio.props"
        :described-by="
          questionLogic.getItemMeta(checkboxRadio.props.value)?.describedBy
        "
        v-on="checkboxRadio.on"
      >
        <template #suffix>
          <checkbox-tags
            :tags="questionLogic.getItemMeta(checkboxRadio.props.value)?.tags"
          />
        </template>
      </s-checkbox-radio>
    </template>
  </s-button-group>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import { toRef } from "vue";

import { SButtonGroup, SCheckboxRadio } from "@solvari/common-fe";

import type { FormComponentWithAnswer } from "@/helpers/types";

import CheckboxTags from "@/components/input/CheckboxTags.vue";
import { useGtmListeners } from "@/composables/useGtmListeners";
import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps({
  question: {
    type: Object as PropType<FormComponentWithAnswer>,
    required: true,
  },
});

const questionLogic = useQuestionLogic<number[] | string[] | undefined>(
  toRef(props, "question"),
);
const gtmListeners = useGtmListeners(toRef(questionLogic.props, "label"));
</script>
