<template>
  <div class="s-textarea">
    <s-label-atom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />
    <s-description
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />
    <s-textarea-atom v-bind="textareaAtom.props" v-on="textareaAtom.on" />
    <s-character-counter
      v-if="!error && characterCounterAtom.if.value"
      v-bind="characterCounterAtom.props"
    />
    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SCharacterCounter from "@/lib/components/atoms/character-counter/SCharacterCounter.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import STextareaAtom from "@/lib/components/atoms/textarea/STextareaAtom.vue";
import useTextarea from "@/lib/components/logic/molecules/useTextarea";

const props = defineProps(useTextarea.props);
const emit = defineEmits(useTextarea.emits);

const {
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  textareaAtom,
  characterCounterAtom,
  subtextAtom,
  error,
  errorComponent,
  errorProps,
} = useTextarea.use(props, emit);
</script>

<style lang="postcss">
.s-textarea {
  @apply flex flex-col;
}
</style>
