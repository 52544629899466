import { toValue } from "@vueuse/core";

import type { EventsCallback } from "./validation.types";

type EventsEnhancer = (
  otherEvents: EventsCallback | string[],
  watchValue?: unknown,
) => EventsCallback;

const aggressive = ["input", "blur"];

const eager: EventsCallback = (error) => {
  return error ? ["input", "change", "blur"] : ["change", "blur"];
};

const onCreatedIfValue: EventsEnhancer = (otherEvents, watchValue) => {
  return (error, value) => {
    const events = Array.isArray(otherEvents)
      ? otherEvents
      : otherEvents(error, value);

    if (toValue(watchValue) || (toValue(watchValue) === undefined && value)) {
      return [...events, "created"];
    }
    return events;
  };
};

export { aggressive, eager, onCreatedIfValue };
