<template>
  <div
    class="s-address--nl-nl"
    :class="{
      's-address--nl-nl--manual': showManual,
      's-address--nl-nl--autocompleted': showAutocompleteResults,
      [`s-address--nl-nl--size-${props.size}`]: true,
    }"
  >
    <s-text-input
      v-model="zipcodeModel"
      autocomplete="postal-code"
      class="s-address--nl-nl__zipcode"
      :maxlength="7"
      name="zipcode"
      required
      :rules="[
        localeSwitchRule(),
        patternRule(zipcodeRegex['nl-NL']),
        zipcodeExistsRule('nl-NL'),
      ]"
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="() => attemptAutocomplete()"
    >
      <template #localeSwitch>
        <s-alert
          class="s-error-atom s-address--nl-nl__locale-switch"
          color="primary"
          :prefix-icon="faCircleExclamation"
          :size="size"
        >
          {{ $t("address.localeWarning.text") }} <br />
          <s-button
            action-type="link"
            size="inline"
            :suffix-icon="faChevronRight"
            @click="switchLocale"
          >
            {{ $t("address.localeWarning.button") }}
          </s-button>
        </s-alert>
      </template>
    </s-text-input>

    <s-combobox
      v-model="houseNumberModel"
      autocomplete="address-line2"
      class="s-address--nl-nl__house-number"
      disable-filter
      :items="houseNumberSuggestions"
      :maxlength="255"
      name="houseNumber"
      :open-on-focus="houseNumberExists === false"
      :open-on-input="false"
      required
      :rules="[houseNumberExistsRule()]"
      show-required-type="none"
      :size="props.size"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="houseNumberValidationTrigger"
      v-on="listeners"
    />

    <s-alert
      v-if="showAutocompleteResults"
      class="s-address--nl-nl__autocomplete"
      color="gray"
      :prefix-icon="faHouse"
      :size="size"
    >
      {{ streetModel }}, {{ cityModel }}
      <s-button
        action-type="quaternary"
        class="s-address--nl-nl__autocomplete__button"
        size="inline"
        @click="setManual"
      >
        {{ $t("address.changeButton") }}
      </s-button>
    </s-alert>

    <template v-if="showManual">
      <s-combobox
        v-model="cityModel"
        autocomplete="address-level2"
        class="s-address--nl-nl__city"
        disable-filter
        :items="citySuggestions"
        :loading="citySuggestionsFetching"
        :maxlength="255"
        name="city"
        required
        :rules="[cityExistsRule()]"
        show-required-type="none"
        :size="props.size"
        :suffix-icon="null"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />

      <s-combobox
        v-model="streetModel"
        autocomplete="address-line1"
        class="s-address--nl-nl__street"
        disable-filter
        :items="streetSuggestions"
        :loading="streetSuggestionsFetching"
        :maxlength="255"
        name="street"
        required
        :rules="[streetExistsRule()]"
        show-required-type="none"
        :size="props.size"
        :suffix-icon="null"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import {
  faChevronRight,
  faCircleExclamation,
  faHouse,
} from "@fortawesome/pro-regular-svg-icons";
import { computedAsync, until, watchImmediate } from "@vueuse/core";
import { isString } from "radash";
import { computed, ref } from "vue";

import type { LocaleIso } from "@/lib/helpers/locales";
import type { ValidateEvent } from "@/lib/validation/ValidationProvider/useValidation";

import {
  autocompleteNlNlAddress,
  getCitySuggestions,
  getHouseNumberSuggestions,
  getStreetSuggestions,
} from "@/lib/api/address.api";
import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import SButton from "@/lib/components/atoms/button/SButton.vue";
import { size as sizeProp } from "@/lib/components/logic/atoms/input/props";
import SCombobox from "@/lib/components/molecules/combobox/SCombobox.vue";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";
import { useLocaleSwitch } from "@/lib/components/organisms/address/useLocaleSwitch";
import { reEmit } from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";
import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { alphabeticCompare } from "@/lib/helpers/strings";
import { asyncThrottle } from "@/lib/helpers/utils/throttleable";
import { eager, onCreatedIfValue } from "@/lib/validation/events";
import { defineRule, zipcodeExistsRule } from "@/lib/validation/rules";
import { patternRule } from "@/lib/validation/rules/native";

const props = defineProps({
  availableLocales: {
    type: Array as PropType<LocaleIso[]>,
    default: () => [],
  },
  size: sizeProp,
  locale: { type: String as PropType<LocaleIso>, default: "nl-NL" },
  zipcode: { type: String as PropType<string | null>, default: "" },
  houseNumber: { type: String as PropType<string | null>, default: "" },
  street: { type: String as PropType<string | null>, default: "" },
  city: { type: String as PropType<string | null>, default: "" },
});

const emit = defineEmits(["blur", "focus", "update:city", "update:houseNumber", "update:locale", "update:street", "update:zipcode", "validationError"]);

const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit, { local: true });
const houseNumberModel = useModel("houseNumber", props, emit, { local: true });
const cityModel = useModel("city", props, emit, { local: true });
const streetModel = useModel("street", props, emit, { local: true });

const showAutocompleteResults = ref(false);
const showManual = ref(false);

function setManual() {
  showManual.value = true;
  showAutocompleteResults.value = false;
}
const autocomplete = asyncThrottle(autocompleteNlNlAddress);

async function attemptAutocomplete(zipcode = zipcodeModel.value) {
  if (!zipcode || !zipcodeRegex["nl-NL"].test(zipcode)) {
    return;
  }
  const result = await autocomplete({
    zipcode: zipcode.replace(" ", "").toUpperCase(),
  });

  if (result instanceof Error || result === null) {
    setManual();
    return;
  }

  showManual.value = false;
  showAutocompleteResults.value = true;
  zipcodeModel.value = result.zipcode;
  streetModel.value = result.street;
  cityModel.value = result.city;
}
void attemptAutocomplete();

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  houseNumber
 */
const suggestHouseNumber = asyncThrottle(getHouseNumberSuggestions);
const houseNumberSuggestionsFetching = ref(false);
const houseNumberExists = ref<boolean | null>(null);

const houseNumberSuggestions = computedAsync(
  () =>
    suggestHouseNumber({
      locale: "nl-NL",
      houseNumber: houseNumberModel.value,
      zipcode: zipcodeModel.value,
    }),
  [],
  { lazy: true, evaluating: houseNumberSuggestionsFetching },
);

const houseNumberExistsRule = defineRule({
  name: "exists",
  validate: async (houseNumber: unknown) => {
    if (!isString(houseNumber) || !houseNumber) {
      return false;
    }
    await until(houseNumberSuggestionsFetching).toBe(false);
    houseNumberExists.value =
      houseNumberSuggestions.value.includes(houseNumber);
    return houseNumberExists.value;
  },
  events: ["blur", "update:zipcode"],
  color: "warning",
  blocking: false,
});

function houseNumberValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcodeModel, () => {
    if (houseNumberModel.value) {
      void validateEvent("update:zipcode");
    }
  });
}

/*
  City
 */
const suggestCity = asyncThrottle(getCitySuggestions);
const citySuggestionsFetching = ref(false);

const citySuggestions = computedAsync(
  async () => {
    return showManual.value
      ? await suggestCity({ locale: "nl-NL", city: cityModel.value })
      : [];
  },
  [],
  { lazy: true, evaluating: citySuggestionsFetching },
);

const cityExistsRule = computed(() =>
  defineRule({
    name: "exists",
    validate: async (city) => {
      if (!city || typeof city !== "string") {
        return false;
      }
      await until(citySuggestionsFetching).toBe(false);
      return citySuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, city),
      );
    },
    events: onCreatedIfValue(["blur"], zipcodeModel.value && cityModel.value),
    color: "warning",
    blocking: false,
  }),
);

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    return showManual.value
      ? await suggestStreet({
          locale: "nl-NL",
          city: cityModel.value,
          street: streetModel.value,
        })
      : [];
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = computed(() =>
  defineRule({
    name: "exists",
    validate: async (street: unknown) => {
      if (!street || typeof street !== "string") {
        return false;
      }
      await until(streetSuggestionsFetching).toBe(false);
      return streetSuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, street),
      );
    },
    events: onCreatedIfValue(["blur"], zipcodeModel.value && streetModel.value),
    color: "warning",
    blocking: false,
  }),
);
</script>

<style lang="postcss">
.s-address--nl-nl {
  @apply grid;
  grid-template-columns: 2fr minmax(min-content, 1fr);
  grid-template-areas: "zipcode house-number";

  &--autocompleted {
    grid-template-areas:
      "zipcode house-number"
      "autocomplete autocomplete";
  }

  &--manual {
    grid-template-areas:
      "zipcode house-number"
      "city ."
      "street .";
  }

  &--size {
    &-sm {
      @apply gap-3;
    }

    &-md {
      @apply gap-4;
    }

    &-lg {
      @apply gap-5;
    }
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__autocomplete {
    @apply justify-self-start;
    grid-area: autocomplete;

    .s-button {
      @apply ml-2;
    }
  }
}
</style>
