import type { PropType, Ref } from "vue";

import { toRef } from "@vueuse/core";
import { omit } from "radash";
import { computed } from "vue";

import type { UseTextInputEmits } from "@/lib/components/logic/molecules/useTextInput";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { LocaleIso } from "@/lib/helpers/locales";
import type { InputValue } from "@/lib/helpers/types";

import {
  useTextInputEmits,
  useTextInputProps,
} from "@/lib/components/logic/molecules/useTextInput";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { emailDomains } from "@/lib/enums/emailDomains";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { bestMatch } from "@/lib/helpers/stringSimilarity";
import { eager } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules";

interface EmailParts {
  domain: string;
  secondLevelDomain: string;
  tld: string;
  username: string;
}

const props = propsDefinition({
  ...omit(useTextInputProps, [
    "type",
    "rules",
    "validationEvents",
    "maxlength",
    "decimals",
    "decimalsFill",
    "min",
    "max",
  ]),
  value: { type: String as PropType<InputValue<string>>, default: null },
  locale: {
    type: String as PropType<LocaleIso>,
    required: true,
    default: "nl-NL",
  },
});

const emits = emitsDefinition(useTextInputEmits);

type UseEmailProps = DefineProps<typeof props>;
type UseEmailEmits = UseTextInputEmits;

function useEmailTypoCorrection(
  email: Ref<Readonly<InputValue<string>>>,
  domains: Ref<readonly string[]>,
  emit: UseEmailEmits,
) {
  const emailParts = computed<EmailParts>(() => {
    if (typeof email.value !== "string") {
      return {
        username: "",
        domain: "",
        tld: "",
        secondLevelDomain: "",
      };
    }
    const parts = email.value.split("@");
    const domain = parts[1]?.toLowerCase() || "";
    const splitDomain = domain.split(".");
    return {
      username: parts[0]?.toLowerCase() || "",
      domain,
      tld: splitDomain[splitDomain.length - 1] || "",
      secondLevelDomain: splitDomain[0] || "",
    };
  });

  const suggestedDomain = computed(() => {
    return bestMatch(emailParts.value.domain, domains.value, {
      threshold: 0.85,
    });
  });

  const suggestion = computed(() => {
    if (
      !suggestedDomain.value ||
      suggestedDomain.value === emailParts.value.domain
    ) {
      return null;
    }
    return `${emailParts.value.username}@${suggestedDomain.value}`;
  });

  function applySuggestion() {
    emit("input", suggestion.value);
  }

  const typoSuggestRule = defineRule({
    name: "emailTypoSuggest",
    validate: (
      _value: number | string | null | undefined,
      params: { apply: () => void; suggestion: Ref<string | null> },
    ) => !params.suggestion,
    events: ["blur"],
    component: "suggestion",
    color: "warning",
    blocking: false,
  })({ suggestion, apply: applySuggestion });

  return { typoSuggestRule, suggestion };
}

function use(props: UseEmailProps, emit: UseEmailEmits) {
  const localizedEmailDomains = computed(() => {
    return emailDomains[props.locale] as string[];
  });

  const { typoSuggestRule } = useEmailTypoCorrection(
    toRef(props, "value"),
    localizedEmailDomains,
    emit,
  );

  return {
    textInput: {
      props: mergeReactive(props, {
        rules: [typoSuggestRule],
        validationEvents: eager,
        type: "email" as const,
        maxlength: 255,
      }),
      on: reEmit(emit, useTextInputEmits),
    },
  };
}

export default { props, emits, use };
