import type { ComponentPropsOptions, ExtractPropTypes } from "vue";
import type {
  ComponentObjectPropsOptions,
  Prop,
} from "vue/types/v3-component-props";

import { reactivePick } from "@/lib/helpers/reactivity";

// Emulates the vue defineProps function
type DefineProps<PP extends ComponentPropsOptions> = Readonly<
  ExtractPropTypes<PP>
>;

// All this does is ensure the correct types for the props
function propsDefinition<const Props extends ComponentObjectPropsOptions>(
  props: Props,
) {
  return props;
}

// Emulates the built-in defineEmits function types. Does nothing for the runtime
function emitsDefinition<Emits extends string>(emits: Emits[]) {
  return emits;
}

function pickProps<
  PropsType extends Readonly<Record<string, unknown>>,
  Keys extends keyof PropsType,
>(props: PropsType, keys: Keys[] | Record<Keys, unknown>) {
  const filterKeys = Array.isArray(keys) ? keys : (Object.keys(keys) as Keys[]);
  return reactivePick(props, filterKeys);
}

/*
  Returns an object with listeners that re-emit the events to the parent
 */
function reEmit<const EmitEvent extends string, const Event extends string>(
  emit: (event: EmitEvent, ...args: never[]) => void,
  events: Event[],
) {
  return events.reduce(
    (listeners, event) => {
      listeners[event] = (...args: never[]) =>
        emit(event as string as EmitEvent, ...args);
      return listeners;
    },
    {} as Record<Event, (...args: never[]) => void>,
  );
}

function propsToDefaults<Props extends ComponentObjectPropsOptions>(
  props: Props,
) {
  return Object.fromEntries(
    Object.entries(props).map(([key, value]) => {
      return [key, propToDefault(value)];
    }),
  ) as ExtractPropTypes<Props>;
}

function propToDefault(value: Prop<unknown, unknown> | null | undefined) {
  if (!value || !("default" in value)) {
    return undefined;
  }
  return (
    typeof value.default === "function" ? value.default() : value.default
  ) as unknown;
}

export type { DefineProps };

export {
  emitsDefinition,
  pickProps,
  propsDefinition,
  propsToDefaults,
  propToDefault,
  reEmit,
};
