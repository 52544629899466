import type { PropType } from "vue";

import { toRef } from "@vueuse/core";
import { computed, reactive, ref } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile } from "@/lib/composables/useFileUpload";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  removable: { type: Boolean, default: true },
});

const props = propsDefinition({
  ...scoped,
  files: { type: Array as PropType<readonly SFile[]>, default: () => [] },
});

const emits = emitsDefinition(["removedFile"]);

type UsePreviewProps = DefineProps<typeof props>;
type UsePreviewEmits = (event: "removedFile", removedUuid: string) => void;

function use(props: UsePreviewProps, emit: UsePreviewEmits) {
  const files = toRef(() => props.files);

  const removable = toRef(() => props.removable);
  function remove(removedUuid: string) {
    if (removable.value) {
      emit("removedFile", removedUuid);
    }
  }

  const lightboxImage = ref<string>();

  function openImage(uuid?: string) {
    lightboxImage.value = uuid;
  }

  const images = computed(() => {
    return files.value.filter(({ mimeType }) => mimeType.includes("image"));
  });

  const lightboxAtom = {
    props: reactive({ images, value: lightboxImage }),
    on: { input: openImage },
  };

  return { files, removable, remove, openImage, lightboxAtom };
}

export type { UsePreviewProps };
export default { props, use, emits };
export {
  emits as usePreviewEmits,
  props as usePreviewProps,
  scoped as usePreviewScoped,
};
