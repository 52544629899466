<template>
  <span
    class="s-checkbox-radio-input"
    :class="{
      's-checkbox-radio-input--checkbox': type === 'checkbox',
      's-checkbox-radio-input--radio': type === 'radio',
      's-checkbox-radio-input--disabled': disabled,
      's-checkbox-radio-input--checked': checked || indeterminate,
    }"
  >
    <input
      class="s-checkbox-radio-input__input"
      v-bind="{ ...$attrs, ...input.props }"
      v-on="input.on"
    />
    <font-awesome-icon
      v-if="type === 'checkbox'"
      aria-hidden="true"
      class="s-checkbox-radio-input__icon"
      :icon="indeterminate ? faMinus : faCheck"
    />
  </span>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { faCheck, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import useCheckboxRadioInput from "@/lib/components/logic/atoms/useCheckboxRadioInput";

const props = defineProps(useCheckboxRadioInput.props);
const emit = defineEmits(useCheckboxRadioInput.emits);

const { input, type, disabled, checked, indeterminate } =
  useCheckboxRadioInput.use(props, emit);
</script>

<style lang="postcss">
.s-checkbox-radio-input {
  @apply grid shrink-0 cursor-pointer grid-cols-1 place-items-center;

  &__input {
    @apply h-5 w-5 appearance-none border border-gray-300 bg-white transition-colors area-[1/1];

    &:hover {
      @apply border-2 border-primary-600;
    }
  }

  &__icon {
    @apply h-3.5 w-3.5 cursor-pointer justify-self-center text-white area-[1/1];
  }

  &--checkbox &__input {
    @apply rounded-sm transition-colors;
  }

  &--radio &__input {
    @apply grid place-items-center rounded-full;

    &::before {
      content: "";
      @apply h-2.5 w-2.5 rounded-full bg-transparent transition-bg;
    }
  }

  &--checked &__input {
    @apply border-2 border-primary-700;
  }

  &--checked&--radio &__input::before,
  &--checked&--checkbox &__input {
    @apply bg-primary-700;
  }

  &--disabled .s-checkbox-radio-input {
    &__input {
      @apply cursor-default bg-gray-100;

      &:hover {
        @apply border border-gray-300;
      }
    }

    &__icon {
      @apply text-gray-100;
    }
  }

  &--disabled&--checked &__input {
    @apply border-2 border-primary-400;
  }

  &--disabled&--checked&--radio &__input::before,
  &--disabled&--checked&--checkbox &__input {
    @apply bg-primary-400;
  }
}
</style>
