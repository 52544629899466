import type { MaybeRefOrGetter } from "@vueuse/core";
import type { DeepReadonly } from "vue";

import { reactiveComputed, toValue } from "@vueuse/core";
import { nanoid } from "nanoid";
import { sift } from "radash";
import { computed } from "vue";

export function useDescribedBy<
  Descriptions extends DeepReadonly<Record<string, string | null | undefined>>,
>(
  descriptions: Descriptions,
  existingString?: MaybeRefOrGetter<Readonly<string | undefined>>,
) {
  const cachedIds: Partial<Record<keyof Descriptions, string>> = {};

  const ids = reactiveComputed(() => {
    return Object.entries(descriptions).reduce<
      Partial<Record<keyof Descriptions, string>>
    >(
      (
        result,
        [name, value]: [keyof Descriptions, string | null | undefined],
      ) => {
        if (!value) {
          result[name] = undefined;
          return result;
        }
        if (!cachedIds[name]) {
          cachedIds[name] = nanoid(10);
        }
        result[name] = cachedIds[name]!;
        return result;
      },
      {},
    );
  });

  const describedBy = computed(() => {
    const idString = sift(Object.values(ids)).join(" ") || undefined;
    const existingStringValue = toValue(existingString);
    if (idString && existingStringValue) {
      return `${idString} ${existingStringValue}`;
    }
    if (existingStringValue) {
      return existingStringValue;
    }
    return idString;
  });

  return { ids, describedBy };
}
