import type { TimeoutError } from "ky";

import { HTTPError } from "ky";
import { nanoid } from "nanoid";
import { curry } from "ramda";

import type { MarketingParams } from "@/lib/argus/events/marketingParams";
import type { ConsentTypes } from "@/lib/components/native/cookie-popup/state";

import { argusTrack } from "@/lib/argus/argus.api";
import { getArgusEvents, getSessionUuid } from "@/lib/argus/client";
import { sSessionStorage } from "@/lib/helpers/localStorage";
import { asyncThrottle, throttle } from "@/lib/helpers/utils/throttleable";
import { getSentry } from "@/lib/integrations/sentry/sentry";

/* eslint-disable @typescript-eslint/naming-convention */
interface ArgusEvents {
  cookie_consent: { consentTypes: ConsentTypes };
  form_submit: {
    id: number | string;
    type: "contact" | "registration";
  };
  generate_lead: { leadId: string };
  google_optimize: {
    experimentId: string;
    variantId: number | string;
  };
  init: { userAgent: string };
  marketing_params: MarketingParams;
  page_view: { pageType: "page" | "referrer"; url: string };
}
/* eslint-enable @typescript-eslint/naming-convention */

interface ArgusEvent<Type extends keyof ArgusEvents = keyof ArgusEvents> {
  createdAt: string;
  data: ArgusEvents[Type];
  status: "pending" | "synced" | "unsynced";
  syncAttempts: string[];
  type: Type;
  uuid: string;
}

function setEvents(mapper: (event: ArgusEvent) => ArgusEvent) {
  sSessionStorage.updateItem(
    "argusEvents",
    (events) => events?.map(mapper) ?? [],
  );
}

const setEventPending = curry((syncAttemptId: string, event: ArgusEvent) => {
  if (event.status !== "synced") {
    return {
      ...event,
      status: "pending",
      syncAttempts: [...event.syncAttempts, syncAttemptId],
    } satisfies ArgusEvent;
  }
  return event;
});

const setEventSynced = curry((syncAttemptId: string, event: ArgusEvent) => {
  if (event.syncAttempts.includes(syncAttemptId)) {
    return { ...event, status: "synced" } satisfies ArgusEvent;
  }
  return event;
});

const setEventUnsynced = curry((syncAttemptId: string, event: ArgusEvent) => {
  if (
    event.status === "pending" &&
    event.syncAttempts.includes(syncAttemptId)
  ) {
    return { ...event, status: "unsynced" } satisfies ArgusEvent;
  }
  return event;
});

type BackendSyncResponse = Promise<
  HTTPError | TimeoutError | TypeError | string | undefined
>;
async function backendSync(
  sessionUuid = getSessionUuid(),
): BackendSyncResponse {
  const eventsToSync = getArgusEvents()?.filter(
    (event) => event.status !== "synced",
  );
  if (!eventsToSync?.length) {
    return;
  }

  const syncAttemptId = nanoid(10);

  setEvents(setEventPending(syncAttemptId));

  const response = await argusTrack(eventsToSync, sessionUuid);

  if (response instanceof Error) {
    // Backend might be down, reset the pending events to unsynced to be tried again on the next attempt
    setEvents(setEventUnsynced(syncAttemptId));

    if (response instanceof HTTPError && response.response.status === 500) {
      // Backend errors get tracked on the backend, reduce FE noise
      return response;
    }

    getSentry().captureException(response, { tags: { project: "argus-fe" } });
    return response;
  }

  setEvents(setEventSynced(syncAttemptId));
  return response;
}

const scheduleBackendSync = throttle(asyncThrottle(backendSync), 1000);

export { scheduleBackendSync };
export type { ArgusEvent, ArgusEvents, BackendSyncResponse };
