import type { MaybeRef } from "@vueuse/core";
import type { DeepReadonly, Ref } from "vue";

import { toValue } from "@vueuse/core";
import { computed } from "vue";

import type { LocaleIso } from "@/lib/helpers/locales";

import {
  autocompleteNlNlAddress,
  getZipcodeCitySuggestions,
} from "@/lib/api/address.api";
import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { onCreatedIfValue } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules";

const alternateLocales = Object.freeze({
  "nl-NL": "nl-BE",
  "nl-BE": "nl-NL",
  "fr-BE": "fr-FR",
  "fr-FR": "fr-BE",
} satisfies Record<LocaleIso, LocaleIso>);

function useLocaleSwitch(
  locale: Ref<LocaleIso>,
  availableLocales: DeepReadonly<MaybeRef<LocaleIso[]>>,
) {
  const alternateLocale = computed(() => alternateLocales[locale.value]);

  function switchLocale() {
    locale.value = alternateLocale.value;
  }

  const localeSwitchRule = defineRule({
    name: "localeSwitch",
    validate: zipcodeLocaleSwitch,
    events: onCreatedIfValue(["blur"]),
    component: "localeSwitch",
    color: "primary",
  });

  async function zipcodeLocaleSwitch(zipcode: unknown) {
    if (typeof zipcode !== "string") {
      return true;
    }

    if (
      !toValue(availableLocales).includes(alternateLocale.value) ||
      zipcodeRegex[locale.value].test(zipcode)
    ) {
      return true;
    }

    if (
      alternateLocale.value === "nl-BE" &&
      zipcodeRegex["nl-BE"].test(zipcode)
    ) {
      await tryNlBESwitch(zipcode);
      return false;
    }

    if (
      alternateLocale.value === "nl-NL" &&
      zipcodeRegex["nl-NL"].test(zipcode)
    ) {
      await tryNlNLSwitch(zipcode);
      return false;
    }

    if (
      alternateLocale.value === "fr-FR" &&
      zipcodeRegex["fr-FR"].test(zipcode)
    ) {
      // No auto switching to fr-FR;
      return false;
    }

    if (
      alternateLocale.value === "fr-BE" &&
      zipcodeRegex["fr-BE"].test(zipcode)
    ) {
      // No auto switching to fr-BE;
      return false;
    }

    // Invalid format
    return true;
  }

  async function tryNlBESwitch(zipcode: string) {
    const nlBESuggestions = await getZipcodeCitySuggestions({
      city: "",
      locale: "nl-BE",
      zipcode,
    });

    const isExistingNlBE = nlBESuggestions.some(
      ({ zipcode: zipcodeSuggestion }) => zipcodeSuggestion === zipcode,
    );

    if (isExistingNlBE) {
      switchLocale();
    }
  }

  async function tryNlNLSwitch(zipcode: string) {
    const nlNLAutocomplete = await autocompleteNlNlAddress({
      zipcode,
    });

    if (nlNLAutocomplete) {
      switchLocale();
    }
  }

  return { localeSwitchRule, switchLocale };
}

export { useLocaleSwitch };
