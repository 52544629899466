import { createArgusEvent } from "@/lib/argus/client";

interface MarketingParams {
  fbclid: string | null;
  gclid: string | null;
  seaAdGroupId: string | null;
  seaAdId: string | null;
  seaCampaignId: string | null;
  seaClickId: string | null;
  seaPlatform: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmTerm: string | null;
}

function addMarketingParamsEvent(marketingParams: MarketingParams) {
  if (Object.values(marketingParams).every((value) => !value)) {
    return;
  }

  return createArgusEvent("marketing_params", marketingParams);
}

function getMarketingParamsFromUrl(
  url = new URL(window.location.href),
): MarketingParams {
  const searchParams = url.searchParams;

  return {
    utmSource: searchParams.get("utm_source"),
    utmMedium: searchParams.get("utm_medium"),
    utmCampaign: searchParams.get("utm_campaign"),
    utmTerm: searchParams.get("utm_term"),
    utmContent: searchParams.get("utm_content"),
    fbclid: searchParams.get("fbclid"),
    gclid: searchParams.get("gclid"),
    seaPlatform: searchParams.get("utm_source"),
    seaCampaignId: searchParams.get("campaign_id"),
    seaAdGroupId: searchParams.get("adgroup_id"),
    seaAdId: searchParams.get("ad_id"),
    seaClickId:
      searchParams.get("gclid") ??
      searchParams.get("fbclid") ??
      searchParams.get("msclid") ??
      searchParams.get("obclid") ??
      searchParams.get("tbclid"),
  };
}

function addMarketingParamsEventFromUrl() {
  return addMarketingParamsEvent(getMarketingParamsFromUrl());
}

export type { MarketingParams };

export { addMarketingParamsEventFromUrl, getMarketingParamsFromUrl };
