import type { MaybeRef } from "@vueuse/core";
import type { DeepReadonly, Ref } from "vue";

import { toValue } from "@vueuse/core";
import { nanoid } from "nanoid";
import { isObject, sort } from "radash";
import { computed, ref, watch } from "vue";

import type {
  OptionItem,
  OptionItemProp,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

import { bestMatch } from "@/lib/helpers/stringSimilarity";
import { arrayWrap } from "@/lib/helpers/utils";

type OptionItemNormalized = Omit<OptionItem, "children"> & {
  children?: readonly OptionValue[];
};

function useOptionsStoreItems(
  items: DeepReadonly<
    Ref<OptionItemProp[] | Record<number | string, string> | string[]>
  >,
  modelValue: DeepReadonly<Ref<OptionValue[]>>,
  name: Readonly<Ref<string>>,
  multiple: Readonly<Ref<boolean>>,
  required: Readonly<Ref<boolean>>,
  disabled: Readonly<Ref<boolean>>,
) {
  interface OptionItemMeta {
    id: string;
    isOpen: boolean;
  }

  const activeItemValue = ref<OptionValue | null>(null);

  const itemsMeta = ref(new Map<OptionValue, OptionItemMeta>());
  function getItemMeta(item: DeepReadonly<OptionItemProp>) {
    const itemMeta = itemsMeta.value.get(item.value);
    if (itemMeta) {
      return itemMeta;
    }

    setItemMeta(item.value, {
      id: nanoid(10),
      isOpen: item.isOpen ?? true,
    });

    return itemsMeta.value.get(item.value)!;
  }
  function setItemMeta(value: OptionValue, itemMeta: Partial<OptionItemMeta>) {
    itemsMeta.value = new Map(
      itemsMeta.value.set(value, {
        ...itemsMeta.value.get(value)!,
        ...itemMeta,
      }),
    );
  }

  const mappedItems = computed<DeepReadonly<OptionItem[]>>(() => {
    return mapItems(items.value);
  });

  function mapItems(
    items: DeepReadonly<
      OptionItemProp[] | Record<number | string, string> | string[]
    >,
  ) {
    if (Array.isArray(items)) {
      return items.map((item: OptionItemProp | string) => fillItem(item));
    }

    return Object.entries(items).map(([key, value]) => {
      return fillItem({
        value: Number.isNaN(Number(key)) ? key : Number(key),
        label: String(value),
      });
    });
  }

  function fillItem(
    item: DeepReadonly<OptionItemProp> | OptionValue,
    checked?: boolean,
  ): OptionItem {
    if (!isObject(item)) {
      item = { value: item, label: item.toString() };
    }
    return {
      name: name.value,
      ...item,
      ...getItemMeta(item),
      isActive: activeItemValue.value === item.value,
      isParent: !!item.children?.length,
      checked: checked ?? modelValue.value.includes(item.value),
      required: item.required || (required.value && !multiple.value),
      type: multiple.value ? "checkbox" : "radio",
      disabled: item.disabled || disabled.value,
      children: item.children ? mapItems(item.children) : undefined,
    };
  }

  function normalizeItem(
    item: DeepReadonly<OptionItem>,
  ): DeepReadonly<OptionItemNormalized[]> {
    if (!item.children) {
      return [item as OptionItemNormalized];
    }
    return [
      { ...item, children: item.children.map(({ value }) => value) },
      ...item.children.map(normalizeItem).flat(),
    ];
  }

  const normalizedItems = computed(() => {
    return mappedItems.value.reduce<DeepReadonly<OptionItemNormalized[]>>(
      (normalized, item) => {
        return normalized.concat(normalizeItem(item));
      },
      [],
    );
  });

  // This kind of makes checkedItems as computed as it updates when it's dependencies change.
  // It's not a computed because it needs to retain some of its previous state
  const checkedItems = ref<DeepReadonly<OptionItemNormalized[]>>([]);
  function syncCheckedItems() {
    checkedItems.value = modelValue.value.reduce<OptionItemNormalized[]>(
      (items, value) => {
        const item =
          findItem(value, normalizedItems) ?? findItem(value, checkedItems);
        if (!item) {
          return items;
        }
        return items.concat({ ...item, checked: true });
      },
      [],
    );
  }

  watch(modelValue, syncCheckedItems, { immediate: true, flush: "sync" });
  watch(normalizedItems, syncCheckedItems);

  // Values that are present in modelValue but were never present in mappedItems are computed into full items,
  // so we can display them correctly.
  const typedItems = computed(() => {
    return arrayWrap(modelValue.value)
      .filter((value) => !findItem(value, checkedItems))
      .map((value) => fillItem(value, true) as OptionItemNormalized);
  });

  const allItems = computed<Readonly<OptionItemNormalized>[]>(() => {
    return sort([...checkedItems.value, ...typedItems.value], ({ value }) =>
      modelValue.value.indexOf(value),
    );
  });

  function findItem<Item extends OptionItem | OptionItemNormalized>(
    value: OptionValue,
    store: DeepReadonly<MaybeRef<Item[]>>,
  ) {
    return (
      toValue(store).find((storedItem) => storedItem.value === value) ?? null
    );
  }

  function findItemIndex<Item extends OptionItem | OptionItemNormalized>(
    value: OptionValue,
    store: DeepReadonly<MaybeRef<Item[]>>,
  ) {
    const index = toValue(store).findIndex(
      (storedItem) => storedItem.value === value,
    );
    return index === -1 ? null : index;
  }

  function matchItemByLabel(label: string, threshold = 1) {
    const match = bestMatch(
      label,
      mappedItems.value.map(({ label }) => label),
      { threshold, caseSensitive: false },
    );

    if (!match) {
      return null;
    }

    return mappedItems.value.find((item) => item.label === match) ?? null;
  }

  function setIsOpen(value: OptionValue, isOpen: boolean) {
    setItemMeta(value, { isOpen });
  }

  return {
    setIsOpen,
    activeItemValue,
    findItem,
    findItemIndex,
    matchItemByLabel,
    normalizedItems,
    mappedItems,
    allItems,
    checkedItems,
    typedItems,
  };
}

export { useOptionsStoreItems };
