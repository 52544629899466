import { toRef } from "@vueuse/core";
import { computed, reactive } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  description: { type: String, required: false },
  size,
});

const props = propsDefinition({
  ...scoped,
  descriptionId: { type: String, required: false },
});

type UseDescriptionProps = DefineProps<typeof props>;

function use(props: UseDescriptionProps) {
  return {
    description: toRef(props, "description"),
    containerProps: reactive({
      size: toRef(props, "size"),
      id: toRef(props, "descriptionId"),
    }),
  };
}

function vIf(props: Pick<UseDescriptionProps, "description">) {
  return computed(() => !!props.description);
}

export type { UseDescriptionProps };
export {
  props as useDescriptionProps,
  scoped as useDescriptionScoped,
  vIf as useDescriptionIf,
};
export default { scoped, props, use };
