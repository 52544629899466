import type { DeepReadonly } from "vue";

import { v4 as createUuid } from "uuid";

import type { ArgusEvent, ArgusEvents } from "@/lib/argus/argusService";

import { scheduleBackendSync } from "@/lib/argus/argusService";
import { getCookie } from "@/lib/helpers/cookies";
import { sSessionStorage } from "@/lib/helpers/localStorage";
import { url as urlRule } from "@/lib/validation/rules/other";

declare global {
  interface Window {
    argusClient?: {
      createArgusEvent?: typeof createArgusEvent;
    };
  }
}

type SessionType = "cross-domain" | "new" | "same-domain";
function getSessionType(): SessionType {
  if (getArgusEvents()?.length) {
    return "same-domain";
  }

  if (new URLSearchParams(window.location.search).get("solvariSession")) {
    return "cross-domain";
  }

  return "new";
}

function getArgusEvents() {
  return sSessionStorage.getItem("argusEvents");
}

function getSessionUuid() {
  return sSessionStorage.getItem("solvariSession");
}

function getSolvariClientId() {
  return getCookie("solvariClientId", false);
}

function createArgusEvent<Type extends keyof ArgusEvents>(
  type: Type,
  data: DeepReadonly<ArgusEvents[Type]>,
): ReturnType<typeof scheduleBackendSync> {
  if (!window.argusClient) {
    window.argusClient = {};
  }
  if (!window.argusClient.createArgusEvent) {
    window.argusClient.createArgusEvent = (type, data) => {
      const event = {
        type,
        data,
        uuid: createUuid(),
        createdAt: new Date().toISOString(),
        status: "unsynced",
        syncAttempts: [],
      } satisfies ArgusEvent;

      sSessionStorage.updateItem("argusEvents", (events) => {
        return events ? [...events, event] : [event];
      });

      return scheduleBackendSync();
    };
  }

  return window.argusClient.createArgusEvent(type, data);
}

function applyArgusUuidToUrl(
  link: string,
  sessionUuid = getSessionUuid(),
  solvariClientId = getSolvariClientId(),
) {
  if (!urlRule().validate(link)) {
    return link;
  }

  const url = new URL(link);
  if (url.hostname === window.location.hostname) {
    return link;
  }

  if (sessionUuid) {
    url.searchParams.set("solvariSession", sessionUuid);
  }
  if (solvariClientId) {
    url.searchParams.set("solvariClientId", solvariClientId);
  }

  return url.href;
}

export {
  applyArgusUuidToUrl,
  createArgusEvent,
  getArgusEvents,
  getSessionType,
  getSessionUuid,
  getSolvariClientId,
};
export type { SessionType };
