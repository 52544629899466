import { toReactive, toRef } from "@vueuse/core";
import { nanoid } from "nanoid";
import { computed, reactive } from "vue";

import type { UseInputAtomEmits } from "@/lib/components/logic/atoms/input/useInputAtom";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation";

import {
  decimals as decimalsProp,
  type as typeProp,
} from "@/lib/components/logic/atoms/input/props";
import {
  useInputAtomEmits,
  useInputAtomScoped,
} from "@/lib/components/logic/atoms/input/useInputAtom";
import {
  useCharacterCounterIf,
  useCharacterCounterScoped,
} from "@/lib/components/logic/atoms/useCharacterCounter";
import {
  useDescriptionIf,
  useDescriptionScoped,
} from "@/lib/components/logic/atoms/useDescription";
import { useLabelScoped } from "@/lib/components/logic/atoms/useLabel";
import {
  useSubtextIf,
  useSubtextScoped,
} from "@/lib/components/logic/atoms/useSubtext";
import { useTooltipScoped } from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy, useModel } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  decimals,
  email,
  max,
  maxLength,
  min,
  minLength,
  number,
  patternRule,
  telephone,
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation";

const useTextInputInferrableRulesProps = propsDefinition({
  type: typeProp,
  pattern: { type: RegExp, required: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  decimals: decimalsProp,
  max: { type: Number, required: false },
  min: { type: Number, required: false },
});

const props = propsDefinition({
  ...useValidationProviderScoped<number | string | null | undefined>(),
  ...useDescriptionScoped,
  ...useLabelScoped,
  ...useTooltipScoped,
  ...useCharacterCounterScoped,
  ...useSubtextScoped,
  ...useInputAtomScoped,
  ...useTextInputInferrableRulesProps,
});

const emits = emitsDefinition([
  "update:loading",
  ...useInputAtomEmits,
  ...useValidationProviderEmits,
]);

type UseTextInputProps = DefineProps<typeof props>;
type UseTextInputEmits = UseInputAtomEmits &
  UseValidationProviderEmits &
  ((event: "update:loading", value: UseTextInputProps["loading"]) => void);
type UseTextInputInferrableRulesProps = DefineProps<
  typeof useTextInputInferrableRulesProps
>;

function inferTextInputRules(props: Partial<UseTextInputInferrableRulesProps>) {
  function getInferredTextRules(
    textProps: Partial<
      Pick<UseTextInputInferrableRulesProps, "maxlength" | "minlength">
    >,
  ) {
    const rules = [];

    if (textProps.maxlength || textProps.maxlength === 0) {
      rules.push(maxLength(textProps.maxlength));
    }

    if (textProps.minlength || textProps.minlength === 0) {
      rules.push(minLength(textProps.minlength));
    }

    return rules;
  }

  function getInferredNumberRules(
    numberProps: Partial<
      Pick<UseTextInputInferrableRulesProps, "decimals" | "max" | "min">
    >,
  ) {
    const rules = [];

    rules.unshift(number());
    rules.unshift(decimals(numberProps.decimals ?? false));

    if (numberProps.max || numberProps.max === 0) {
      rules.unshift(max(numberProps.max));
    }

    if (numberProps.min || numberProps.min === 0) {
      rules.unshift(min(numberProps.min));
    }

    return rules;
  }

  return computed(() => {
    const rules = [];
    if (props.type === "email") {
      rules.push(email());
    }
    if (props.type === "tel") {
      rules.push(telephone());
    }

    if (props.type === "number") {
      rules.push(...getInferredNumberRules(props));
    } else {
      rules.push(...getInferredTextRules(props));
    }

    if (props.pattern) {
      rules.push(patternRule(props.pattern));
    }

    return rules;
  });
}

function use(props: UseTextInputProps, emit: UseTextInputEmits) {
  const loading = useModel("loading", props, emit, { local: true });

  const { label, tooltip, description, subtext, errorLabel } = useAutoI18n(
    toRef(props, "name"),
    reactivePick(props, [
      "label",
      "tooltip",
      "description",
      "subtext",
      "errorLabel",
    ]),
  );

  const id = nanoid(10);
  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  const { validationListeners, error, errorComponent, errorProps, inputProps } =
    useValidationProvider(
      mergeReactive(props, {
        loading,
        errorLabel,
        rules: [...inferTextInputRules(props).value, ...props.rules],
      }),
      emit,
    );

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabelScoped), { for: id, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltipScoped), {
      tooltipId: toRef(ids, "tooltip"),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescriptionIf(props),
    props: mergeReactive(pickProps(props, useDescriptionScoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const inputAtom = {
    props: mergeReactive(pickProps(props, useInputAtomScoped), inputProps, {
      id,
      describedBy,
    }),
    on: mergeListeners(
      reEmit(emit, useInputAtomEmits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtextIf(props),
    props: mergeReactive(pickProps(props, useSubtextScoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  const characterCounterAtom = {
    if: useCharacterCounterIf(props),
    props: pickProps(props, useCharacterCounterScoped),
  };

  return {
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    inputAtom,
    characterCounterAtom,
    subtextAtom,
    error,
    errorComponent,
    errorProps,
  };
}

export type { UseTextInputEmits, UseTextInputProps };
export {
  emits as useTextInputEmits,
  inferTextInputRules,
  props as useTextInputProps,
  useTextInputInferrableRulesProps,
};
export default { props, emits, use };
