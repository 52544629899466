import { pipe } from "ramda";

import { url } from "@/lib/ab-testing/attributes";
import { redirectToUrl } from "@/lib/ab-testing/helpers";
import { applyArgusUuidToUrl } from "@/lib/argus";
import { extendUrlParams } from "@/lib/helpers/url";

function getAbTestFeature<Type>(key: string) {
  return window.abTesting?.getFeatureValue<Type | null>(key, null) ?? null;
}

function splitUrlFeature(redirect = redirectToUrl) {
  const splitUrl = getAbTestFeature<string>("split-url");
  if (!splitUrl) {
    return;
  }
  const currentPage = url();

  if (currentPage === splitUrl) {
    return;
  }
  pipe(applyArgusUuidToUrl, extendUrlParams, redirect)(splitUrl);
}

export { getAbTestFeature, splitUrlFeature };
