import type { PropType } from "vue";

import { toRef } from "@vueuse/core";
import { reactive } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useElementValue } from "@/lib/composables/useElementValue";

const props = propsDefinition({
  name: { type: String, required: true },
  id: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  value: { type: [String, Number, Boolean], required: false },
  checked: { type: Boolean, default: false },
  type: { type: String as PropType<"checkbox" | "radio">, default: "checkbox" },
  describedBy: { type: String, required: false },
});

type UseCheckboxRadioInputProps = DefineProps<typeof props>;
interface UseCheckboxRadioInputEmits {
  (event: "input", value: boolean): void;
  (event: "blur" | "focus", name: string): void;
}
const emits = emitsDefinition(["input", "focus", "blur"]);

function use(
  props: UseCheckboxRadioInputProps,
  emit: UseCheckboxRadioInputEmits,
) {
  function onInput({ target }: Event) {
    const checked = (target as HTMLInputElement).checked;
    emit("input", checked);
  }

  function onFocus() {
    emit("focus", props.name);
  }

  function onBlur() {
    emit("blur", props.name);
  }

  return {
    input: {
      props: reactive({
        id: toRef(() => props.id),
        name: toRef(() => props.name),
        value: useElementValue(toRef(() => props.value)),
        disabled: toRef(() => props.disabled),
        required: toRef(() => props.required),
        indeterminate: toRef(() => props.indeterminate),
        type: toRef(() => props.type),
        checked: toRef(() => props.checked),
        "aria-describedby": toRef(() => props.describedBy),
      }),
      on: {
        click: onInput,
        focus: onFocus,
        blur: onBlur,
      },
    },
    type: toRef(() => props.type),
    disabled: toRef(() => props.disabled),
    checked: toRef(() => props.checked),
    indeterminate: toRef(() => props.indeterminate),
  };
}

export type { UseCheckboxRadioInputEmits, UseCheckboxRadioInputProps };
export {
  emits as useCheckboxRadioInputEmits,
  props as useCheckboxRadioInputProps,
};
export default { use, props, emits };
