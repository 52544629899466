import type { Ref } from "vue";

import { computed, nextTick, ref, watch } from "vue";

import type { ValidationRule } from "@/lib/validation/validation.types";

import { required } from "@/lib/validation/rules/native";

type ValidateEvent = (event: string) => Promise<void>;

function useValidation<Value>(
  modelValue: Readonly<Ref<Value>>,
  loading: Ref<boolean>,
  failedRule: Ref<ValidationRule<Value> | null>,
  blockingRules: Ref<ValidationRule<Value>[]>,
  allRules: Ref<ValidationRule<Value>[]>,
) {
  const validatedRules = ref<string[]>([]);

  watch(modelValue, () => {
    validatedRules.value = [];
  });

  const notRequiredAndWithoutValue = computed(() => {
    // This is necessary because most rules will fail is there's no value
    // Even if the field is not required
    return (
      !allRules.value.some((rule) => rule.name === "required") &&
      !required<Value>().validate(modelValue.value)
    );
  });

  async function validateAll() {
    await validate(allRules.value);
  }

  async function validateBlocking() {
    if (blockingRules.value.length) {
      await validate(blockingRules.value);
    }
  }

  async function validateEvent(event: string) {
    const rulesToValidate = allRules.value.filter((rule) => {
      return rule.events.includes(event);
    });
    if (rulesToValidate.length) {
      await validate(rulesToValidate);
    }
  }

  async function validateSilent(rules: ValidationRule<Value>[]) {
    await nextTick();
    if (notRequiredAndWithoutValue.value) {
      return null;
    }

    const validationResults = await Promise.all(
      rules.map((rule) => rule.validate(modelValue.value)),
    );

    for (let index = 0; index < validationResults.length; index++) {
      if (!validationResults[index]) {
        return rules[index] ?? null;
      }
    }

    return null;
  }

  async function validate(rules: ValidationRule<Value>[]) {
    loading.value = true;
    failedRule.value = await validateSilent(rules);
    loading.value = false;
    validatedRules.value = [
      ...new Set([...validatedRules.value, ...rules.map(({ name }) => name)]),
    ];
  }

  return {
    validatedRules,
    validate,
    validateAll,
    validateBlocking,
    validateEvent,
    validateSilent,
  };
}

export type { ValidateEvent };
export { useValidation };
