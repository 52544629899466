import type { ComputedRef, Ref } from "vue";

import { computed, watch } from "vue";

import type { ValidationRule } from "@/lib/validation/validation.types";

import type { ValidateEvent } from "./useValidation";

function useListeners<Value>(
  modelValue: Readonly<Ref<Value>>,
  validateEvent: ValidateEvent,
  allRules: ComputedRef<ValidationRule<Value>[]>,
) {
  const flatValidationEvents = computed(() => {
    return [...new Set(allRules.value.flatMap(({ events }) => events))];
  });

  const hasInputEventValidation = computed(() => {
    return flatValidationEvents.value.includes("input");
  });

  const validationListeners = computed<Record<string, () => Promise<void>>>(
    () => {
      return Object.fromEntries(
        flatValidationEvents.value
          .filter(
            (eventName: string) => !["input", "created"].includes(eventName),
          )
          .map((eventName: string) => [
            eventName,
            () => validateEvent(eventName),
          ]),
      );
    },
  );

  watch(modelValue, () => {
    if (hasInputEventValidation.value) {
      void validateEvent("input");
    }
  });

  void validateEvent("created");

  return validationListeners;
}

export { useListeners };
