import type { PropType } from "vue";

import { toRef, useTextareaAutosize } from "@vueuse/core";
import { computed, reactive, watchEffect } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { InputValue } from "@/lib/helpers/types";

import { size } from "@/lib/components/logic/atoms/input/props";
import {
  useInputWrapperAtomProps,
  useInputWrapperAtomScoped,
} from "@/lib/components/logic/atoms/input/useInputWrapperAtom";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useTemplateRef } from "@/lib/composables/useTemplateRef";

const scoped = propsDefinition({
  ...useInputWrapperAtomScoped,
  name: { type: String, required: true },
  placeholder: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  size,
  autoResize: {
    type: [Boolean, Object] as PropType<
      boolean | { max?: number; min?: number }
    >,
    default: false,
  },
  value: {
    type: String as PropType<InputValue<string>>,
    default: null,
  },
});

const props = propsDefinition({
  ...useInputWrapperAtomProps,
  ...scoped,
  id: { type: String, required: false },
  describedBy: { type: String, required: false },
  invalid: { type: Boolean, default: false },
});

const emits = emitsDefinition(["blur", "focus", "input"]);
type UseTextareaAtomProps = DefineProps<typeof props>;
type UseTextareaAtomEmits = (
  event: "blur" | "focus" | "input",
  name: string,
) => void;

function use(props: UseTextareaAtomProps, emit: UseTextareaAtomEmits) {
  const inputWrapperAtom = {
    props: pickProps(props, useInputWrapperAtomProps),
  };

  const { el: textarea, ref: textareaRef } =
    useTemplateRef<HTMLTextAreaElement>();
  const computedUseTextArea = computed(() => {
    return props.autoResize && textarea.value ? textarea.value : undefined;
  });
  useTextareaAutosize({
    input: toRef(() => props.value ?? ""),
    element: computedUseTextArea,
  });

  const height = computed(() => {
    const baseAutoResizeHeightValues = { min: 32, max: 128 };
    if (props.autoResize === true) {
      return baseAutoResizeHeightValues;
    }
    if (!props.autoResize) {
      return { min: baseAutoResizeHeightValues.min, max: 999 };
    }
    const min = props.autoResize.min ?? baseAutoResizeHeightValues.min;
    const max = props.autoResize.max ?? baseAutoResizeHeightValues.max;
    return {
      min: Math.min(min, max),
      max: Math.max(min, max),
    };
  });

  watchEffect(() => {
    if (textarea.value) {
      textarea.value.style.minHeight = `${height.value.min * 4}px`;
      textarea.value.style.maxHeight = `${height.value.max * 4}px`;
      textarea.value.style.resize = props.autoResize ? "none" : "vertical";
    }
  });

  function onBlur() {
    emit("blur", props.name);
  }

  function onFocus() {
    emit("focus", props.name);
  }

  const onInput = (event: InputEvent) => {
    const inputValue = (event.target as HTMLInputElement).value;
    emit("input", inputValue);
  };

  return {
    inputWrapperAtom,
    input: {
      props: reactive({
        id: toRef(() => props.id),
        name: toRef(() => props.name),
        placeholder: toRef(() => props.placeholder),
        disabled: toRef(() => props.disabled),
        required: toRef(() => props.required),
        "aria-invalid": props.invalid ? ("true" as const) : undefined,
        maxlength: toRef(() => props.maxlength),
        minlength: toRef(() => props.minlength),
        "aria-describedby": toRef(() => props.describedBy),
        value: toRef(() => props.value ?? undefined),
      }),
      on: {
        input: onInput,
        blur: onBlur,
        focus: onFocus,
      },
      ref: textareaRef,
    },
    size: toRef(() => props.size),
    height,
  };
}

export {
  emits as useTextareaAtomEmits,
  props as useTextareaAtomProps,
  scoped as useTextareaAtomScoped,
};
export type { UseTextareaAtomEmits };
export default { props, use, emits, scoped };
