import { reactiveComputed, toRef } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  disabled: { type: Boolean, default: false },
  size,
  showValidState: { type: Boolean, default: true },
});

const props = propsDefinition({
  ...scoped,
  valid: { type: Boolean, default: false },
  tag: { type: String, default: "div" },
  color: { type: String, default: null },
});

type UseInputWrapperAtomProps = DefineProps<typeof props>;

function use(props: UseInputWrapperAtomProps) {
  const wrapper = {
    props: reactiveComputed(() => {
      if (props.tag === "button") {
        return {
          type: "button",
          disabled: props.disabled,
        };
      }
      return {};
    }),
  };

  const showValid = computed(() => props.valid && props.showValidState);

  return {
    wrapper,
    tag: toRef(props, "tag"),
    disabled: toRef(props, "disabled"),
    valid: showValid,
    color: toRef(props, "color"),
    size: toRef(props, "size"),
  };
}

export type { UseInputWrapperAtomProps };
export {
  props as useInputWrapperAtomProps,
  scoped as useInputWrapperAtomScoped,
};
export default { scoped, props, use };
