function isEqual(value1: unknown, value2: unknown): boolean {
  if (value1 === value2 || (Number.isNaN(value1) && Number.isNaN(value2))) {
    return true;
  }

  if (
    typeof value1 !== "object" ||
    value1 === null ||
    typeof value2 !== "object" ||
    value2 === null
  ) {
    return false;
  }

  if (Array.isArray(value1) && Array.isArray(value2)) {
    return isEqualArray(value1, value2);
  }
  if (Array.isArray(value1) || Array.isArray(value2)) {
    return false;
  }

  if (value1 instanceof Date && value2 instanceof Date) {
    return value1.getTime() === value2.getTime();
  }
  if (value1 instanceof Date || value2 instanceof Date) {
    return false;
  }

  if (value1 instanceof RegExp && value2 instanceof RegExp) {
    return value1.toString() === value2.toString();
  }
  if (value1 instanceof RegExp || value2 instanceof RegExp) {
    return false;
  }

  return isEqualObject(value1, value2);
}

function isEqualArray(value1: unknown[], value2: unknown[]) {
  if (value1.length !== value2.length) {
    return false;
  }

  return !value1.some((value, index) => {
    return !isEqual(value, value2[index]);
  });
}

function isEqualObject(value1: object, value2: object) {
  const value1Keys = Object.keys(value1);
  const value2Keys = Object.keys(value2);
  if (value1Keys.length !== value2Keys.length) {
    return false;
  }

  return !value1Keys.some((key) => {
    // Returns true so we can stop the loop early
    if (!value2Keys.includes(key)) {
      return true;
    }
    return !isEqual(
      (value1 as Record<PropertyKey, unknown>)[key],
      (value2 as Record<PropertyKey, unknown>)[key],
    );
  });
}

export { isEqual, isEqualArray, isEqualObject };
