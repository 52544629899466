<template>
  <s-alert v-if="component === 'alert'" class="s-error-atom" :color="color">
    {{ error }}
  </s-alert>
  <div
    v-else
    class="s-error-atom s-error-atom--text"
    :class="[`s-error-atom--${color}`]"
  >
    {{ error }}
  </div>
</template>

<script setup lang="ts">
import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import useError from "@/lib/components/logic/atoms/useError";

const props = defineProps(useError.props);
const { component, error, color } = useError.use(props);
</script>

<style lang="postcss">
.s-error-atom {
  @apply mt-1;

  &--text {
    @apply text-sm;
  }

  &--primary {
    @apply text-primary-700;
  }

  &--success {
    @apply text-success-500;
  }

  &--warning {
    @apply text-warning-500;
  }

  &--danger {
    @apply text-danger-500;
  }

  &--gray {
    @apply text-gray-700;
  }
}
</style>
