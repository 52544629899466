import type { Placement } from "@floating-ui/vue";
import type { PropType } from "vue";

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/vue";
import { toRef, useFocusWithin, useMouseInElement } from "@vueuse/core";
import { computed, useSlots, watchEffect } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { propsDefinition } from "@/lib/composables/componentComposable";
import { useTemplateRef } from "@/lib/composables/useTemplateRef";

const scoped = propsDefinition({
  tooltip: { type: String, required: false },
  disabled: { type: Boolean, default: false },
});

const props = propsDefinition({
  ...scoped,
  tooltipId: { type: String, required: false },
  dark: { type: Boolean, default: true },
  placement: { type: String as PropType<Placement>, default: "top" },
  fallbackPlacements: {
    type: Array as PropType<Placement[]>,
    default: () => ["top", "right", "bottom", "left"],
  },
  offset: { type: Number, default: 8 },
  padding: { type: Number, default: 12 },
  anchorEl: { type: HTMLElement, required: false },
  triggerEl: { type: HTMLElement, required: false },
  isOpen: { type: Boolean as PropType<boolean | null>, default: null },
  openOnHover: { type: Boolean, default: true },
  updateOnAnimationFrame: { type: Boolean, default: false },
});

type UseTooltipProps = DefineProps<typeof props>;

function use(props: UseTooltipProps) {
  const disabled = computed(() => {
    return props.disabled || (!props.tooltip && !useSlots().default);
  });

  const { el: autoTriggerEl, ref: triggerRef } = useTemplateRef();
  const { el: popupEl, ref: popupRef } = useTemplateRef();
  const { el: arrowEl, ref: arrowRef } = useTemplateRef();

  const triggerEl = computed(() => {
    return props.triggerEl ?? autoTriggerEl.value;
  });

  const anchorEl = computed<HTMLElement | null>(() => {
    return props.anchorEl ?? triggerEl.value;
  });

  const { isOutside } = useMouseInElement(triggerEl);
  const { focused } = useFocusWithin(triggerEl);

  const isOpen = computed(() => {
    return (
      props.isOpen ?? ((!isOutside.value && props.openOnHover) || focused.value)
    );
  });

  const { floatingStyles, middlewareData, placement } = useFloating(
    anchorEl,
    popupEl,
    {
      placement: toRef(() => props.placement),
      whileElementsMounted: (...args) =>
        autoUpdate(...args, {
          animationFrame: props.updateOnAnimationFrame,
        }),
      middleware: computed(() => [
        offset(props.offset),
        flip({
          fallbackPlacements: props.fallbackPlacements,
          crossAxis: false,
          padding: props.padding,
        }),
        shift({ padding: props.padding }),
        arrow({ element: arrowEl }),
      ]),
    },
  );

  watchEffect(() => {
    if (popupEl.value) {
      Object.assign(popupEl.value.style, floatingStyles.value);
    }
  });

  watchEffect(() => {
    if (!middlewareData.value.arrow || !arrowEl.value) {
      return;
    }

    const { x: left, y: top } = middlewareData.value.arrow;

    const staticSide = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[placement.value.split("-")[0]!]!;

    Object.assign(arrowEl.value.style, {
      left: left != null ? `${left}px` : "",
      top: top != null ? `${top}px` : "",
      [staticSide]: `${-arrowEl.value.offsetWidth / 2}px`,
    });
  });

  return {
    triggerRef,
    popupRef,
    arrowRef,
    isOpen,
    tooltip: toRef(() => props.tooltip),
    dark: toRef(() => props.dark),
    disabled,
    id: toRef(() => props.tooltipId),
  };
}

export type { UseTooltipProps };
export { props as useTooltipProps, scoped as useTooltipScoped };
export default { use, props };
