import { isEqual } from "@/lib/helpers/utils/isEqual";
import { defineRule } from "@/lib/validation/rules/defineRule";

const is = defineRule({ name: "is", validate: isEqual });

const url = defineRule({
  name: "url",
  validate: (value: URL | string | null | undefined) => {
    if (!value) {
      return false;
    }
    try {
      const url = new URL(value);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (error) {
      return false;
    }
  },
});

export { is, url };
