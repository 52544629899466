import type { PropType } from "vue";

import { toRef } from "@vueuse/core";
import { reactive, watch } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import { useTemplateRef } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useElementValue } from "@/lib/composables/useElementValue";

const props = propsDefinition({
  id: { type: String, required: false },
  value: { type: [String, Number, Boolean], required: false },
  size,
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  checked: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  type: { type: String as PropType<"checkbox" | "radio">, default: "radio" },
  isActive: { type: Boolean, default: false },
  label: { type: String, default: "" },
  name: { type: String, required: true },
});

const emits = emitsDefinition(["click"]);

type UseOptionProps = DefineProps<typeof props>;
type UseOptionEmits = (event: "click", checked: boolean) => void;

function use(props: UseOptionProps, emit: UseOptionEmits) {
  const { el, ref } = useTemplateRef();

  function onClick() {
    if (!props.disabled) {
      emit("click", !props.checked);
    }
  }

  watch(
    toRef(() => props.isActive),
    (active) => {
      if (!active || !el.value) {
        return;
      }
      if (
        "scrollIntoViewIfNeeded" in el.value &&
        typeof el.value.scrollIntoViewIfNeeded === "function"
      ) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
        el.value.scrollIntoViewIfNeeded(false);
      } else {
        el.value.scrollIntoView(false);
      }
    },
  );

  const input = {
    if: toRef(() => props.type === "checkbox"),
    props: reactive({
      tabindex: "-1",
      "aria-hidden": true,
      checked: toRef(() => props.checked),
      disabled: toRef(() => props.disabled),
      indeterminate: toRef(() => props.indeterminate),
      name: toRef(() => props.name),
    }),
  };

  const option = {
    ref,
    props: reactive({
      role: "option",
      id: toRef(() => props.id),
      "aria-selected": toRef(() => props.checked),
      "aria-disabled": toRef(() => props.disabled),
      value: useElementValue(toRef(() => props.value)),
    }),
    on: {
      click: onClick,
    },
  };

  return {
    input,
    option,
    label: toRef(() => props.label),
    disabled: toRef(() => props.disabled),
    checked: toRef(() => props.checked),
    isActive: toRef(() => props.isActive),
    size: toRef(() => props.size),
  };
}

export { emits as useOptionEmits, props as useOptionProps };
export default { use, props, emits };
