<template>
  <fieldset class="s-button-group" v-bind="container.props">
    <s-label-atom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />
    <s-description
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />
    <slot name="checkboxRadioGroup" v-bind="checkboxRadioGroup">
      <s-checkbox-radio-group
        v-bind="checkboxRadioGroup.props"
        v-on="checkboxRadioGroup.on"
      >
        <template v-for="(_index, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </s-checkbox-radio-group>
    </slot>
    <s-subtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent?.toString()" v-bind="errorProps">
      <s-error-atom v-bind="errorProps" />
    </slot>
  </fieldset>
</template>

<script setup lang="ts">
import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SCheckboxRadioGroup from "@/lib/components/atoms/checkbox-radio-group/SCheckboxRadioGroup.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import useButtonGroup from "@/lib/components/logic/molecules/useButtonGroup";

const props = defineProps(useButtonGroup.props);
const emit = defineEmits(useButtonGroup.emits);

/*defineSlots*/

const {
  container,
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  checkboxRadioGroup,
  subtextAtom,
  error,
  errorComponent,
  errorProps,
} = useButtonGroup.use(props, emit);
</script>
