import type { VueInstance } from "@vueuse/core";

import { ref } from "vue";

export type TemplateRef = <El extends HTMLElement>(
  el: El | VueInstance | null,
) => void;

export function useTemplateRef<ElementType = HTMLElement>() {
  const element = ref<ElementType | null>(null);

  // https://vuejs.org/guide/essentials/template-refs.html#function-refs
  const bindElement = <El extends ElementType>(el: El | VueInstance | null) => {
    // @ts-expect-error Using some vue internals
    element.value = el && "_isVue" in el ? (el.$el as HTMLElement) : el;
  };

  return {
    ref: bindElement,
    el: element,
  };
}
