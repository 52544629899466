import type { PropType } from "vue";

import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type {
  OptionItem,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  removable: { type: Boolean, default: false },
});

const props = propsDefinition({
  ...scoped,
  items: {
    type: Array as PropType<Pick<OptionItem, "label" | "value">[]>,
    default: () => [],
  },
  variant: { type: String as PropType<"clear" | "opaque">, default: "clear" },
});

const emits = ["removeValue"];

type UseMultiValueDisplayProps = DefineProps<typeof props>;
type UseMultiValueDisplayEmits = (
  event: "removeValue",
  value: OptionValue,
) => void;

function use(
  props: UseMultiValueDisplayProps,
  emit: UseMultiValueDisplayEmits,
) {
  function removeValue(removedValue: OptionValue) {
    if (props.removable) {
      emit("removeValue", removedValue);
    }
  }

  const itemsLabels = computed(() => props.items.map(({ label }) => label));

  const tag = computed(() => (props.removable ? "button" : "span"));

  return {
    shouldShow: toRef(() => Boolean(props.items.length)),
    items: toRef(() => props.items),
    itemsLabels,
    removeValue,
    removable: toRef(() => props.removable),
    tag,
    variant: toRef(() => props.variant),
  };
}

export type { UseMultiValueDisplayProps };
export default { scoped, props, use, emits };
export {
  emits as useMultiValueDisplayEmits,
  props as useMultiValueDisplayProps,
  scoped as useMultiValueDisplayScoped,
};
